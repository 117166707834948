import React, { useState, useEffect, useRef } from "react";
import { View, Text, Image, TouchableOpacity, SafeAreaView, ActivityIndicator, Modal, ImageBackground, Button, ScrollView, TextInput, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Table, Row, Rows } from "react-native-table-component-2";
import AsyncStorage from "../components/AsyncStorage";
import axios from "axios";
import { API_MIDDLEWARE, API_REGION } from "@env";
import moment from "moment";
import i18n from "../components/i18n";
import CalendarPicker from "react-native-calendar-picker";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import { WebView } from "react-native-webview";

const transactionDetails = () => {
  const navigation = useNavigation();

  const [tableHead, setTableHead] = useState([i18n.t("transactionDetails.transaction_date"), i18n.t("transactionDetails.outlet"), i18n.t("transactionDetails.receipt_no"), i18n.t("transactionDetails.amount_spend"), i18n.t("transactionDetails.amount_qualified_for_i_dollar"), i18n.t("transactionDetails.i_dollars"), i18n.t("transactionDetails.action")]);
  const [tranOverlay, setTranOverlay] = useState(false);
  const [memberCode, setMemberCode] = useState("");
  const [memberRegion, setMemberRegion] = useState("");
  const [member, setMember] = useState({});
  const [transactionView, setTransactionView] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [fullTransactions, setFullTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [spendingSlide, setSpendingSlide] = useState({
    spendingTotal: 15,
    spendingCount: 1,
    spendingPoint: 10,
  });
  const [txnPeriod, setTxnPeriod] = useState(1); // set init as current month
  const [calendarView, setCalendarView] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [isCalendarPicker, setIsCalendarPicker] = useState(false);
  const [calBtnColor, setCalBtnColor] = useState(false);
  const [storeList, setStoreList] = useState({});
  const [outlet, setOutlet] = useState("");

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    AsyncStorage.getData("mCode").then((value) => {
      if (!value) {
        navigation.push("Login");
      } else {
        let loginDetail = JSON.parse(value);
        setMemberCode(loginDetail.code);
        setMemberRegion(loginDetail.region);

        let data = {
          code: loginDetail.code,
          username: loginDetail.username,
          region: loginDetail.region,
        };

        customerGet(data, (customer) => {
          // console.log("Customer :", customer);
          setIsLoading(false);

          customer.spend_required = parseInt(customer.spend_required);
          customer.spend_this_tier = parseInt(customer.spend_this_tier);

          setMember(customer);

          let spending = spendingSlide;
          // console.log("spending :", spending);

          let totalSpending = customer.spend_required + customer.spend_this_tier;
          // console.log("totalSpending :", totalSpending);

          let slidePercent = parseInt((customer.spend_this_tier / totalSpending) * 100);
          // console.log("slidePercent :", slidePercent);

          if (slidePercent > 0) {
            spending.spendingTotal = slidePercent + 1;
          } else {
            spending.spendingTotal = 0;
          }

          if (slidePercent > 5) {
            spending.spendingPoint = slidePercent;
          } else {
            spending.spendingPoint = 0;
          }

          if (slidePercent > 15) {
            spending.spendingCount = slidePercent + 15;
          }

          let tranData = {
            code: loginDetail.code,
            from_date: moment().subtract(1, "month").format("YYYY-MM-DD"),
            to_date: moment().endOf("month").format("YYYY-MM-DD"),
            region: loginDetail.region,
          };
          // console.log("tranData :", tranData);

          let tempStoreList = {};

          getStoreList((returnList) => {
            // console.log("returnList :", returnList);
            for (let i = 0; i < returnList.store.length; i++) {
              tempStoreList[returnList.store[i].code] = returnList.store[i];
            }

            // console.log("tempStoreList :", tempStoreList);
            // console.log("Type of tempStoreList :", typeof tempStoreList);

            setStoreList(tempStoreList);

            txnGet(tranData, (transactions) => {
              // console.log("transactions :", transactions);
              setIsLoading(false);

              if (transactions.status == "success") {
                let transData = transactions.data;

                setFullTransactions(transData);
                let trans = [];
                let storeName = "";

                if (transData.length > 0) {
                  for (let i = 0; i < transData.length; i++) {
                    if (transData[i].invoiceno.indexOf("-local") > -1) {
                      storeName = transData[i].store; // show store code only
                    } else if (transData[i].store != null) {
                      if (tempStoreList[transData[i].store]) {
                        storeName = tempStoreList[transData[i].store].staffname;
                        if (i18n.locale == "zh") {
                          if (tempStoreList[transData[i].store].ch_name != null) {
                            storeName = tempStoreList[transData[i].store].ch_name;
                          } else {
                            storeName = tempStoreList[transData[i].store].staffname;
                          }
                        }
                      } else {
                        storeName = transData[i].store; // show store code only
                      }
                    } else if (transData[i].store == null || transData[i].store == "") {
                      storeName = "N/A";
                    }

                    transData[i].storename = storeName;

                    trans.push([dateFormat(transData[i].txn_date), storeName, transData[i].invoiceno, "KRW" + currencyFormat((parseFloat(transData[i].nett_amount) + parseFloat(transData[i].service_charge) + parseFloat(transData[i].tax)).toFixed(2)), parseInt(transData[i].nett_amount), "+" + transData[i].points, viewTransaction(transData[i])]);

                    setOutlet(storeName);
                  }
                }

                setTransactions(trans);

                setIsLoading(false);
              }
            });
          });
        });
      }
    });

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setTableHead([i18n.t("transactionDetails.transaction_date"), i18n.t("transactionDetails.outlet"), i18n.t("transactionDetails.receipt_no"), i18n.t("transactionDetails.amount_spend"), i18n.t("transactionDetails.amount_qualified_for_i_dollar"), i18n.t("transactionDetails.i_dollars"), i18n.t("transactionDetails.action")]);
  }, [setTableHead]);

  const viewTransactionDetail = (tran) => {
    setTransactionView(tran);
    setTranOverlay(true);
  };

  const closeTransactionDetail = () => {
    setTranOverlay(false);
  };

  const viewTransaction = (val) => {
    return (
      <TouchableOpacity onPress={() => viewTransactionDetail(val)}>
        <View>
          <Text className="text-brandgold font-PingFangSC">{i18n.t("transactionDetails.view")}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  const customerGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getCust");
    formData.append("code", data.code);
    formData.append("username", data.username);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const txnGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getTxn");
    formData.append("code", data.code);
    formData.append("from", data.from_date);
    formData.append("to", data.to_date);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const txnPeriodChange = (type, day1, day2) => {
    setTxnPeriod(day1);

    let tranData = {};

    if (type == "calendarPicker") {
      let startDate, endDate;

      if (selectedStartDate) {
        startDate = selectedStartDate;
      }

      if (selectedEndDate) {
        endDate = selectedEndDate;
      } else {
        endDate = startDate;
      }

      if (!selectedStartDate && !selectedEndDate) {
        setTxnPeriod(day1);
        // console.log("No start date and end date selected...");
        startDate = moment().subtract(day1, "month").format("YYYY-MM-DD");
        endDate = startDate;

        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);

        console.log("start date :", startDate);
        console.log("end date :", endDate);
      }

      tranData = {
        code: member.code,
        from_date: startDate,
        to_date: endDate,
        region: memberRegion,
      };

      setCalendarView(false);
    } else {
      tranData = {
        code: member.code,
        from_date: moment().subtract(day1, "month").format("YYYY-MM-DD"),
        to_date: moment().endOf("month").format("YYYY-MM-DD"),
        region: memberRegion,
      };
    }

    // Checking:
    // console.log("tranData :", tranData);
    console.log("tranData-period :", tranData.from_date + " to " + tranData.to_date);

    txnGet(tranData, (transactions) => {
      // console.log("txnGet-transactions :", transactions);
      setIsLoading(false);
      if (transactions.status == "success") {
        let transData = transactions.data;
        // console.log("txnGet-transData :", transData);

        setFullTransactions(transData);
        let trans = [];
        let storeName = "";

        // console.log(storeList);

        let tempStoreList = storeList;

        if (transData.length > 0) {
          for (let i = 0; i < transData.length; i++) {
            if (transData[i].invoiceno.indexOf("-local") > -1) {
              storeName = transData[i].store; // show store code only
            } else if (transData[i].store != null) {
              if (tempStoreList[transData[i].store]) {
                storeName = tempStoreList[transData[i].store].staffname;
                if (i18n.locale == "zh") {
                  if (tempStoreList[transData[i].store].ch_name != null) {
                    storeName = tempStoreList[transData[i].store].ch_name;
                  } else {
                    storeName = tempStoreList[transData[i].store].staffname;
                  }
                }
              } else {
                storeName = transData[i].store; // show store code only
              }
            } else if (transData[i].store == null || transData[i].store == "") {
              // console.log("no storeName");
              storeName = "N/A";
            }

            transData[i].storename = storeName;

            trans.push([dateFormat(transData[i].txn_date), storeName, transData[i].invoiceno, "KRW" + currencyFormat((parseFloat(transData[i].nett_amount) + parseFloat(transData[i].service_charge) + parseFloat(transData[i].tax)).toFixed(2)), parseInt(transData[i].nett_amount), "+" + transData[i].points, viewTransaction(transData[i])]);

            setOutlet(storeName);
          }
        }

        setTransactions(trans);
      }
    });

    if (type == "calendarPicker") {
      setIsCalendarPicker(true);
    } else {
      setIsCalendarPicker(false);
      setCalBtnColor(false);
    }
  };

  const dateFormat = (date) => {
    if (date && date != "N/A") {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "N/A";
    }
  };

  const capitalize = (string) => {
    if (string) {
      return string.replace(/^./, (str) => str.toUpperCase());
    } else {
      return "";
    }
  };

  const currencyFormat = (str) => {
    if (str) {
      str = str.toString();
      return str.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return "";
    }
  };

  const onDateChange = (date, type) => {
    // console.log("date :", date);
    console.log("type :", type); // START_DATE or END_DATE

    if (type === "END_DATE") {
      // console.log("onDateChange-end date selected on calendar screen");
      setSelectedEndDate(moment(date).format("YYYY-MM-DD"));
    } else {
      // console.log("onDateChange-start date selected on calendar screen");
      setSelectedEndDate(null);
      setSelectedStartDate(moment(date).format("YYYY-MM-DD"));
    }
  };

  const initSelectedDate = () => {
    setCalendarView(true);
    setCalBtnColor(true);

    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  const getStoreList = async (callback) => {
    let formData = new FormData();
    formData.set("method", "getStoreList");
    formData.set("region", API_REGION);

    try {
      const response = await axios.post(API_MIDDLEWARE, formData);
      callback(response.data);
    } catch (error) {
      // console.log("getStoreList error :", error);
      throw error;
    }
  };

  return (
    <SafeAreaView className="min-h-full bg-[#F5F5F5]" pointerEvents={!isLoading ? "auto" : "none"}>
      {isLoading && (
        <View className="left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-full" style={{ position: "fixed" }} pointerEvents={"none"}>
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}

      {/* Transaction Record Area */}
      <View className="w-full md:w-4/5 max-w-7xl p-4 md:p-0 md:m-auto md:mt-3 ">
        <View className="bg-white p-4">
          <View className="flex flex-col md:flex-row md:justify-between md:mb-6">
            <View>
              <Text className="text-xl font-semibold text-black font-Crimson">{i18n.t("profile.transaction")}</Text>
            </View>

            <View className="bg-none md:bg-[#F2F3F5] pl-0 pr-3 pt-4 pb-3 flex flex-row flex-wrap md:flex-nowrap md:w-1/2 justify-between !gap-0">
              <TouchableOpacity className="p-0 md:px-3 md:py-[2px] w-1/2 md:w-[25%] md:flex-auto" onPress={() => txnPeriodChange("btn", 1)}>
                <Text className={txnPeriod == 1 ? "p-1 md:px-3 md:py-[2px] text-sm font-normal text-white bg-brandgold font-PingFangSC text-center md:whitespace-nowrap" : "p-1 md:px-3 md:py-[2px] text-sm font-normal text-[#4E5969] font-PingFangSC text-center md:whitespace-nowrap"}>{i18n.t("profile.this_month")}</Text>
              </TouchableOpacity>

              <TouchableOpacity className="p-0 md:px-3 md:py-[2px] w-1/2 md:w-[25%] md:flex-auto" onPress={() => txnPeriodChange("btn", 3)}>
                {}
                <Text className={txnPeriod == 3 ? "p-1 md:px-3 md:py-[2px] text-sm font-normal text-white bg-brandgold font-PingFangSC text-center md:whitespace-nowrap" : "p-1 md:px-3 md:py-[2px] text-sm font-normal text-[#4E5969] font-PingFangSC text-center md:whitespace-nowrap"}>{i18n.t("profile.last_3_months")}</Text>
              </TouchableOpacity>

              <TouchableOpacity className="p-0 md:px-3 md:py-[2px] w-1/2 md:w-[25%] md:flex-auto" onPress={() => txnPeriodChange("btn", 12)}>
                <Text className={txnPeriod == 12 ? "p-1 md:px-3 md:py-[2px] text-sm font-normal text-white bg-brandgold font-PingFangSC text-center md:whitespace-nowrap" : "p-1 md:px-3 md:py-[2px] text-sm font-normal text-[#4E5969] font-PingFangSC text-center md:whitespace-nowrap"}>{i18n.t("profile.last_12_months")}</Text>
              </TouchableOpacity>

              {/* Calendar Button */}
              <TouchableOpacity className="p-0 top-0.5 w-1/2 md:block md:w-[5%]" onPress={() => initSelectedDate()}>
                {calBtnColor ? (
                  <View className="relative px-1 bg-brandgold">
                    <FontAwesomeIcon name="calendar" resizeMode="contain" className="text-white text-base text-center p-0.5"></FontAwesomeIcon>
                  </View>
                ) : (
                  <View className="relative px-1">
                    <FontAwesomeIcon name="calendar" resizeMode="contain" className="text-[#4E5969] text-base text-center p-0.5"></FontAwesomeIcon>
                  </View>
                )}
              </TouchableOpacity>
            </View>
          </View>

          {/* Mobile UI Display */}
          <View className="md:hidden">
            {isCalendarPicker && (
              <View className="flex-row mb-6 font-normal text-[#84818A] font-Crimson justify-evenly">
                <View>
                  <Text className="flex text-brandgold font-PingFangSC mt-1 mb-1">{i18n.t("transactionDetails.selected_start_date")} :</Text>

                  <Text className="flex justify-center items-center">{selectedStartDate ? selectedStartDate : ""}</Text>
                </View>

                <View>
                  <Text className="flex text-brandgold font-PingFangSC mt-1">{i18n.t("transactionDetails.selected_end_date")} :</Text>

                  <Text className="flex justify-center items-center">{selectedEndDate ? selectedEndDate : selectedStartDate}</Text>
                </View>
              </View>
            )}

            {fullTransactions.length > 0 &&
              fullTransactions.map((item, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    onPress={() => {
                      viewTransactionDetail(item);
                    }}
                  >
                    <View className="mb-1">
                      <View className="flex flex-row justify-between items-center">
                        <Text className="underline text-sm font-normal text-[#2E2C34] font-Crimson">{item.invoiceno}</Text>
                        <Text className=" text-base font-bold text-[#2E2C34] font-Crimson">KRW{item.amount}</Text>
                      </View>
                      <View className="flex flex-row justify-between items-center">
                        <Text className="text-xs font-normal text-[#84818A] font-Crimson">{item.store}</Text>
                        <Text className="text-xs font-normal text-[#84818A] font-Crimson">+{item.points} i-dollars</Text>
                      </View>
                      <View className="flex flex-row justify-between items-center">
                        <Text className="text-xs font-normal text-[#84818A] font-Crimson">{item.txn_date}</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              })}

            {fullTransactions.length <= 0 && <Text className="text-sm font-normal text-[#84818A] font-Crimson flex items-center justify-center mt-10 mb-20">{i18n.t("transactionDetails.no_tranMsg")}</Text>}
          </View>

          {/* Transaction details (only web screen) */}
          <View className="hidden md:flex">
            {isCalendarPicker && (
              <View className="flex-row mb-5 font-normal text-[#84818A] font-Crimson justify-evenly">
                <View className="flex-row">
                  <Text className="flex text-brandgold font-PingFangSC">{i18n.t("transactionDetails.selected_start_date")} : </Text>

                  <Text className="flex justify-center items-center">{selectedStartDate ? selectedStartDate : ""}</Text>
                </View>

                <View className="flex-row">
                  <Text className="flex text-brandgold font-PingFangSC">{i18n.t("transactionDetails.selected_end_date")} : </Text>

                  <Text className="flex justify-center items-center">{selectedEndDate ? selectedEndDate : selectedStartDate}</Text>
                </View>
              </View>
            )}

            <Table borderStyle={{ borderWidth: 1, borderColor: "transparent" }}>
              <Row
                data={tableHead}
                style={{
                  minHeight: 40,
                  paddingVertical: 5,
                  backgroundColor: "rgba(189, 155, 96, 0.1)",
                  borderBottomWidth: 1,
                  borderBottomColor: "#E5E6EB",
                }}
                textStyle={{ margin: 5, fontFamily: "PingFang-SC-Regular" }}
              />

              <Rows
                data={transactions}
                style={{
                  minHeight: 40,
                  borderBottomWidth: 1,
                  borderBottomColor: "#E5E6EB",
                }}
                textStyle={{ margin: 5, fontFamily: "PingFang-SC-Regular" }}
              />
            </Table>

            {fullTransactions.length <= 0 && <Text className="text-sm font-normal text-[#84818A] font-Crimson flex items-center justify-center mt-10 mb-20">{i18n.t("transactionDetails.no_tranMsg")}</Text>}
          </View>
        </View>
      </View>

      {/* Transaction - Action - View button */}
      {tranOverlay && transactionView && (
        <View className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]" style={{ position: "fixed" }}>
          <View className="w-11/12 max-w-lg min-h-[300px] md:max-h-[80%] max-h-[70%] bg-white px-6 py-3 relative" style={{ overflow: "auto" }}>
            <TouchableOpacity className="absolute right-1 top-1" onPress={() => closeTransactionDetail()}>
              <Image className="w-5 h-5" source={require("../assets/btn-close.png")} />
            </TouchableOpacity>

            <Text className="text-xl font-semibold text-[#111111] text-center py-3 uppercase font-Crimson">{i18n.t("profile.transaction")}</Text>
            <Text className="text-base font-medium pt-6 pb-4 font-PingFangSC">{i18n.t("profile.transaction_details")}</Text>

            <View className="flex flex-row items-center justify-start pb-3">
              <Text className="text-sm font-medium text-[#86909C] min-w-[160px] font-PingFangSC">{i18n.t("profile.outlet")}</Text>
              <Text className="text-sm font-normal text-[#111111] font-PingFangSC">
                {transactionView.storename}
                {/* {outlet} */}
              </Text>
            </View>

            <View className="flex flex-row items-center justify-start pb-3">
              <Text className="text-sm font-medium text-[#86909C] min-w-[160px] font-PingFangSC">{i18n.t("profile.transaction_date")}</Text>
              <Text className="text-sm font-normal text-[#111111] font-PingFangSC">{dateFormat(transactionView.txn_date)}</Text>
            </View>

            <View className="flex flex-row items-center justify-start pb-3">
              <Text className="text-sm font-medium text-[#86909C] min-w-[160px] font-PingFangSC">{i18n.t("profile.transaction_time")}</Text>
              <Text className="text-sm font-normal text-[#111111] font-PingFangSC">{transactionView.txn_date.split(" ")[1]}</Text>
            </View>

            <View className="flex flex-row items-center justify-start pb-3">
              <Text className="text-sm font-medium text-[#86909C] min-w-[160px] font-PingFangSC">{i18n.t("profile.receipt")}</Text>
              <Text className="text-sm font-normal text-[#111111] font-PingFangSC max-w-[200px] md:max-w-[300px] whitespace-break-spaces">{transactionView.invoiceno}</Text>
            </View>

            {transactionView.payment.length > 0 && (
              <>
                <View className="flex flex-row items-start justify-start pb-3">
                  <Text className="text-sm font-medium text-[#86909C] min-w-[160px] font-PingFangSC">{i18n.t("profile.payment_method")}</Text>
                  <View className="flex flex-col gap-2">
                    {transactionView.payment
                      .filter((p) => p.source === "request")
                      .map((p, idx) => {
                        return (
                          <Text key={idx} className="text-sm font-normal text-[#111111] font-PingFangSC">
                            {transactionView.payment.length == 1 ? "" : "- "}
                            {p.type}
                          </Text>
                        );
                      })}
                  </View>
                </View>
              </>
            )}

            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

            <Text className="text-base font-medium pt-6 pb-4 font-PingFangSC">{i18n.t("profile.receipt_details")}</Text>
            {transactionView.items.length &&
              transactionView.items.map((item, index) => {
                return (
                  <View className="flex flex-row items-center justify-between pb-3" key={index}>
                    <Text className="text-sm font-medium text-[#86909C] min-w-[160px] font-PingFangSC">
                      {item.name} *{item.qty}
                      {/* {i18n.t("profile.zero")} */}
                    </Text>
                    <Text className="text-sm font-normal text-[#111111] font-PingFangSC">KRW{parseFloat(item.line_total).toFixed(2)}</Text>
                  </View>
                );
              })}
            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

            <View className="text-right">
              <Text className="pt-4 pb-2 text-sm font-normal text-[#303030] font-PingFangSC">
                {i18n.t("profile.i_dollars_eared")} {transactionView.points}
              </Text>
              <View className="flex flex-row flex-wrap items-center justify-end pt-2 pb-6 text-sm font-normal text-[#303030]">
                <Text className="font-PingFangSC">{i18n.t("profile.total")}</Text>
                <Text className="font-PingFangSC">
                  {" KRW"}
                  {currencyFormat(transactionView.amount)}
                </Text>
              </View>
            </View>

            <Text className="text-brandgold text-sm font-normal font-PingFangSC flex justify-center items-center">{i18n.t("profile.thankyou_msg")}</Text>

            <View className="text-right flex items-end">
              <TouchableOpacity className="w-[66px] h-8 max-h-8 bg-brandgold mt-4 mr-0" onPress={() => closeTransactionDetail()}>
                <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">{i18n.t("profile.done")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}

      <Modal animationType="slide" visible={calendarView} transparent={true}>
        <View className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000050]" style={{ position: "fixed" }}>
          <View className="w-11/12 max-w-lg min-h-[300px] bg-white px-6 py-3 relative">
            <CalendarPicker
              startFromMonday={true}
              allowRangeSelection={true}
              minDate={new Date(2018, 1, 1)}
              maxDate={new Date(2050, 6, 3)}
              weekdays={["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"]}
              months={["January", "Febraury", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]}
              previousTitle="Previous"
              nextTitle="Next"
              todayBackgroundColor="#decdaf"
              selectedDayColor="#caa060"
              selectedDayTextColor="#000000"
              scaleFactor={375}
              textStyle={{
                fontFamily: "Cochin",
                color: "#000000",
              }}
              onDateChange={onDateChange}
              width={350}
              height={400}
            />

            <TouchableOpacity
              onPress={() => {
                txnPeriodChange("calendarPicker", selectedStartDate, selectedEndDate);
              }}
            >
              <Text className="flex text-brandgold font-PingFangSC font-bold justify-center items-center mt-1 text-lg">{i18n.t("transactionDetails.apply")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

export default transactionDetails;
