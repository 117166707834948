import React from "react";
import { View, ScrollView, Text, Image, ImageBackground, TouchableOpacity, SafeAreaView, Linking } from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import Header from "../components/header";
import i18n from "../components/i18n";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";

const Outlets = () => {
  const navigation = useNavigation();

  return (
    <View className="flex-1 md:bg-[#efefef]">
      <ImageBackground source={require("../assets/login-bg.png")} resizeMode="cover" className="flex-1 justify-start items-center w-full h-full">
        <SafeAreaView className="flex-1 bg-white flex justify-start items-center w-full md:bg-transparent">
          <Header />
          <NewMobileHeader />
          <MobileHeader title={i18n.t("outlets.outlets")} />
          <ScrollView className="w-full flex-1">
            <View className="w-11/12 md:w-4/5 md:max-w-screen-xl md:h-auto m-auto md:bg-white md:p-7 mt-10 md:mb-12">
              <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">{i18n.t("outlets.outlets")}</Text>
              <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

              <View className="mt-6">
                <View className="hidden md:flex flex-row bg-[#D9D9D9]">
                  <Text className="w-[25%] px-5 py-3 text-base font-semibold font-Crimson">{i18n.t("outlets.restaurant_name")}</Text>
                  <Text className="w-[35%] px-5 py-3 text-base font-semibold font-Crimson">{i18n.t("outlets.address")}</Text>
                  <Text className="w-[15%] px-5 py-3 text-base font-semibold font-Crimson">{i18n.t("outlets.contact")}</Text>
                  <Text className="w-[25%] px-5 py-3 text-base font-semibold font-Crimson">{i18n.t("outlets.email")}</Text>
                </View>
                <View className="flex flex-col md:flex-row bg-white">
                  <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_1_1")}</Text>
                  <Text className="w-full md:w-[35%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_1_2")}</Text>
                  <Text className="w-full md:w-[15%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_1_3")}</Text>
                  <TouchableOpacity
                    onPress={() => {
                      Linking.openURL("mailto:mbs@imperialtreasure.com");
                    }}
                    className="w-full md:w-[25%] p-0 md:p-5"
                  >
                    <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_1_4")}</Text>
                  </TouchableOpacity>
                </View>
                <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
                <View className="flex flex-col md:flex-row bg-white">
                  <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_2_1")}</Text>
                  <Text className="w-full md:w-[35%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_2_2")}</Text>
                  <Text className="w-full md:w-[15%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_2_3")}</Text>
                  <TouchableOpacity
                    onPress={() => {
                      Linking.openURL("mailto:paragon@imperialtreasure.com");
                    }}
                    className="w-full md:w-[25%] p-0 md:p-5"
                  >
                    <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_2_4")}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </ScrollView>
          <Footer />
          <CookieConsent />
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

export default Outlets;
