import React, { useState, useEffect, useRef, Component } from "react";
import { View, Text, StyleSheet, ImageBackground, TouchableOpacity, SafeAreaView, Dimensions, Linking, ScrollView, Image, TextInput, Button } from "react-native";
import Modal from "react-native-modal";
import { useNavigation, useRoute } from "@react-navigation/native";
import i18n from "./i18n";
import AsyncStorage from "./AsyncStorage";
import RNPickerSelect from "react-native-picker-select";

const { width } = Dimensions.get("window");

function NewMobileHeader(props) {
  const navigation = useNavigation();
  const route = useRoute();

  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState("");
  const [languageErr, setLanguageErr] = useState(false);
  const [drawerOverlay, setDrawerOverlay] = useState(false);
  const [langSwitch, setLangSwitch] = useState(false); // Language switching modal handling
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    AsyncStorage.getData("mCode").then((value) => {
      // console.log(route.name);
      if (route.name == "Login") {
        AsyncStorage.removeValue("mCode");
      }

      // else if (!value) {
      //   navigation.push("Login");
      // }

      AsyncStorage.getData("mCode").then((value) => {
        if (value) {
          setIsLoggedIn(true);
        }
      });
    });

    return () => {
      mounted.current = false;
    };
  }, []);

  const drawerChangePage = (pageName) => {
    navigation.push(pageName);
    setDrawerOverlay(false);
  };

  const switchLang = (lang) => {
    i18n.locale = lang;
    AsyncStorage.storeData("lang", lang);
    location.reload();
  };

  const switchLangV1 = (lang) => {
    console.log(lang);
    setIsLoading(true);
    setLanguageErr(false);

    if (language == "") {
      setLanguageErr(true);
      setIsLoading(false);
      return;
    }

    i18n.locale = lang;
    console.log("current language :", lang);

    AsyncStorage.storeData("lang", lang);
    // location.reload();
    // Updates.reloadAsync();

    navigation.replace(
      route.name, // current page/screen
      null, // no params
      null, // no "sub-action"
      Math.random().toString()
    );

    setIsLoading(false);
    setLangSwitch(false);
  };

  const logoutHandling = () => {
    AsyncStorage.removeValue("mCode");
    // Updates.reloadAsync();
    navigation.push("Login");
    setDrawerOverlay(false);
  };

  return (
    <View className="w-full bg-white md:hidden">
      {isLoading && (
        <View className="absolute left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-full" pointerEvents={"none"}>
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}
      <SafeAreaView className="flex flex-row relative justify-between items-center content-center">
        <ImageBackground source={require("../assets/login-bg.png")} resizeMode="cover" className="flex h-24 flex flex-row items-center w-full">
          <View className="flex flex-row justify-between items-center w-full my-1">
            <View>
              <TouchableOpacity onPress={() => setDrawerOverlay(true)}>
                <ImageBackground source={require("../assets/burger-btn.png")} resizeMode="contain" className="w-10 h-10 ml-6" />
              </TouchableOpacity>
            </View>

            <View className="flex items-center">
              <ImageBackground source={require("../assets/logo.png")} resizeMode="contain" className="h-20 w-36" />
            </View>

            <View>
              {isLoggedIn == false && (
                <TouchableOpacity onPress={() => navigation.push("Login")}>
                  <ImageBackground source={require("../assets/login-btn.png")} resizeMode="contain" className="w-10 h-10 mr-6" />
                </TouchableOpacity>
              )}
              {isLoggedIn && (
                <TouchableOpacity onPress={() => navigation.push("Profile")}>
                  <ImageBackground source={require("../assets/login-btn.png")} resizeMode="contain" className="w-10 h-10 mr-6" />
                </TouchableOpacity>
              )}
            </View>
          </View>
        </ImageBackground>
      </SafeAreaView>

      <Modal
        // isVisible={this.state.isModalVisible}
        // onSwipeComplete={this.toggleSideMenu} // Swipe to discard
        isVisible={drawerOverlay}
        animationIn="slideInLeft" // Has others, we want slide in from the left
        animationOut="slideOutLeft" // When discarding the drawer
        onSwipeComplete={() => setDrawerOverlay(false)}
        swipeDirection="left" // Discard the drawer with swipe to left
        // useNativeDriver // Faster animation
        hideModalContentWhileAnimating // Better performance, try with/without
        propagateSwipe // Allows swipe events to propagate to children components (eg a ScrollView inside a modal)
        backdropOpacity={0.5}
        style={drawerStyles.sideMenuStyle} // Needs to contain the width, 75% of screen width in our case
        onBackdropPress={() => setDrawerOverlay(false)} // Android back press
      >
        <View className="bg-[#CAB180] h-screen flex align-middle pt-7 pl-8">
          <View className="h-10 mb-4 items-start justify-center">
            <TouchableOpacity onPress={() => setDrawerOverlay(false)}>
              <ImageBackground source={require("../assets/burger-btn.png")} resizeMode="contain" className="w-10 h-10" />
            </TouchableOpacity>
          </View>

          <View>
            <TouchableOpacity className="w-auto h-8 max-h-8 my-2" onPress={() => drawerChangePage("Login")}>
              <Text className="uppercase text-black text-xl font-bold font-Crimson">{i18n.t("header.home")}</Text>
            </TouchableOpacity>

            <TouchableOpacity className="w-auto h-8 max-h-8 my-2 hidden" onPress={() => Linking.openURL("https://estore.imperialtreasure.com/")}>
              <Text className="uppercase text-black text-xl font-bold font-Crimson">{i18n.t("header.eStore")}</Text>
            </TouchableOpacity>

            <TouchableOpacity className="w-auto h-8 max-h-8 my-2 " onPress={() => drawerChangePage("Privilege")}>
              <Text className="uppercase text-black text-xl font-bold font-Crimson">{i18n.t("header.PRivileges")}</Text>
            </TouchableOpacity>

            <TouchableOpacity className="w-auto h-8 max-h-8 my-2" onPress={() => drawerChangePage("SignUp")}>
              <Text className="uppercase text-black text-xl font-bold font-Crimson">{i18n.t("header.register")}</Text>
            </TouchableOpacity>

            <TouchableOpacity className="w-auto h-8 max-h-8 my-2" onPress={() => Linking.openURL("https://www.imperialtreasure.com/korea/LG-en/happenings")}>
              <Text className="uppercase text-black text-xl font-bold font-Crimson">{i18n.t("header.highlights")}</Text>
            </TouchableOpacity>

            <TouchableOpacity className="w-auto h-8 max-h-8 my-2" onPress={() => drawerChangePage("FAQ")}>
              <Text className="uppercase text-black text-xl font-bold font-Crimson">{i18n.t("header.faq")}</Text>
            </TouchableOpacity>

            <TouchableOpacity className="w-auto h-8 max-h-8 my-2" onPress={() => drawerChangePage("Tnc")}>
              <Text className="uppercase text-black text-xl font-bold font-Crimson">{i18n.t("header.tnp")}</Text>
            </TouchableOpacity>

            <TouchableOpacity className="w-auto h-8 max-h-8 my-2" onPress={() => drawerChangePage("LanguageSetting")}>
              <Text className="uppercase text-black text-xl font-bold font-Crimson">{i18n.t("languageSetting.title")}</Text>
            </TouchableOpacity>

            <View className="flex flex-row justify-start items-center flex-wrap mt-8 mb-1">
              <TouchableOpacity onPress={() => Linking.openURL("https://www.facebook.com/imperialtreasurekr/")}>
                <ImageBackground source={require("../assets/icons/fb-icon.png")} resizeMode="contain" className="w-6 h-6"></ImageBackground>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => Linking.openURL("https://www.instagram.com/imperialtreasurekr/")}>
                <ImageBackground source={require("../assets/icons/ig-icon.png")} resizeMode="contain" className="w-6 h-6 ml-4"></ImageBackground>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const drawerStyles = StyleSheet.create({
  sideMenuStyle: {
    margin: 0,
    width: width * 0.75, // SideMenu width
  },
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
  inputAndroid: {
    color: "#86909C",
    height: 38,
    fontSize: 15,
    lineHeight: 38,
    width: "100%",
    backgroundColor: "#F2F3F5",
    borderColor: "#F2F3F5",
    borderWidth: 1,
    paddingHorizontal: 15,
  },
  inputWeb: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
});

export default NewMobileHeader;
