import React from "react";
import {
  View,
  Text,
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  Image,
  TextInput,
  SafeAreaView,
  Button,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import i18n from "../components/i18n";
import AsyncStorage from "../components/AsyncStorage";

function Header(props) {
  const navigation = useNavigation();

  const switchLang = (lang) => {
    i18n.locale = lang;
    AsyncStorage.storeData("lang", lang);
    location.reload();
  };

  return (
    <View className="hidden justify-start align-middle w-full md:flex">
      <ImageBackground
        source={require("../assets/logo.png")}
        resizeMode="contain"
        className=" w-32 h-44 block my-6 self-center"
      ></ImageBackground>
      <View className="bg-brandgold w-full h-12 min-h-[48px] flex-1 flex-row justify-center align-middle items-center">
        <TouchableOpacity
          onPress={() => {
            navigation.push("Login");
          }}
        >
          <Text className="text-sm uppercase text-[#111111] font-Crimson">
            {i18n.t("header.home")}
          </Text>
        </TouchableOpacity>
        <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
        <TouchableOpacity
          onPress={() => {
            navigation.push("Privilege");
          }}
        >
          <Text className="text-sm uppercase text-[#111111] font-Crimson">
            {i18n.t("header.PRivileges")}
          </Text>
        </TouchableOpacity>
        <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
        <TouchableOpacity
          onPress={() => {
            navigation.push("SignUp");
          }}
        >
          <Text className="text-sm uppercase text-[#111111] font-Crimson">
            {i18n.t("header.register")}
          </Text>
        </TouchableOpacity>
        <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
        <TouchableOpacity
          onPress={() => {
            window.open("https://www.imperialtreasure.com/korea/LG-en/happenings");
          }}
        >
          <Text className="text-sm uppercase text-[#111111] font-Crimson">
            {i18n.t("header.highlights")}
          </Text>
        </TouchableOpacity>
        <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
        <TouchableOpacity
          onPress={() => {
            navigation.push("FAQ");
          }}
        >
          <Text className="text-sm uppercase text-[#111111] font-Crimson">
            {i18n.t("header.faq")}
          </Text>
        </TouchableOpacity>
        <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
        <TouchableOpacity
          onPress={() => {
            navigation.push("Tnc");
          }}
        >
          <Text className="text-sm uppercase text-[#111111] font-Crimson">
            {i18n.t("header.tnp")}
          </Text>
        </TouchableOpacity>

        {i18n.locale != "en" && (
          <>
            <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
            <TouchableOpacity
              onPress={() => {
                switchLang("en");
                // console.log("Language change to English");
              }}
            >
              <Text className="text-sm uppercase text-[#111111] font-Crimson">
                ENGLISH
              </Text>
            </TouchableOpacity>
          </>
        )}

        {i18n.locale != "zh" && (
          <>
            <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
            <TouchableOpacity
              onPress={() => {
                switchLang("zh");
                // console.log("Language change to Chinese");
              }}
            >
              <Text className="text-sm uppercase text-[#111111] font-Crimson">
                中文
              </Text>
            </TouchableOpacity>
          </>
        )}

        {i18n.locale != "kr" && (
          <>
            <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
            <TouchableOpacity
              onPress={() => {
                switchLang("kr");
                // console.log("Language change to Korean");
              }}
            >
              <Text className="text-sm uppercase text-[#111111] font-Crimson">
                한국어
              </Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    </View>
  );
}

export default Header;
