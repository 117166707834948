export default {
  login: {
    login: "로그인",
    email: "이메일 주소",
    mobile: "휴대폰 번호",
    mobile2: "휴대폰 번호",
    password: "비밀번호",
    errMsg1: "이메일과 비밀번호를 입력해주세요",
    errMsg2: "휴대폰 번호와 비밀번호를 입력해주세요",
    errMsg3: "계정이 이 지역이 아닙니다",
    errMsg4: "잘못된 로그인 또는 비밀번호",
    errMsg5: "잘못된 로그인 모바일 또는 비밀번호",
    errMsg6: "결제가 완료될 때까지 등록이 완료되지 않습니다",
    forgotpw: "비밀번호 찾기",
    register: "회원가입",
    txt: "아직 임페리얼 트레저 클럽 회원이 아니십니까? ",
    txt_2: "멤버십 혜택을 확인하세요",
  },
  header: {
    home: "로그인",
    eStore: "e-스토어",
    PRivileges: "특전",
    register: "회원가입",
    highlights: "프로모션 및 이벤트",
    faq: "자주 묻는 질문(FAQ)",
    tnp: "이용 약관 및 개인 정보 보호",
    profile: "프로필",
  },
  footer: {
    aboutUs: "회사 소개",
    eStore: "e-스토어",
    faq: "자주 묻는 질문(FAQ)",
    tnp: "이용 약관 및 개인 정보 보호",
    pr: "참여 레스토랑",
    profile: "프로필",
    contactUs: "문의하기",
    copyright: "2023©  임페리얼 트레져 레스토랑 그룹",
  },
  signUp: {
    signUp: "멤버십 등록",
    title: "임페리얼 트레져 클럽 멤버십 회원가입",
    mustFill: '"*"는 필수 필드를 나타냅니다',
    step1: "회원 정보",
    step2: "비밀번호 설정",
    step3: "약관동의",
    step4: "기타",
    salutation: "호칭",
    salutation_opt1: "호칭을 입력해주세요",
    salutation_opt2: "미스터",
    salutation_opt3: "미세스",
    salutation_opt4: "마담",
    salutation_opt5: "미스",
    salutation_opt6: "닥터미스터",
    salutation_errMsg: "호칭을 입력해주세요",
    firstname: "이름",
    firstname_errMsg: "이름을 입력해주세요",
    lastname: "성",
    lastname_errMsg: "성을 입력해주세요",
    birth: "생일",
    birth_errMsg: "생일을 입력해주세요",
    birth_errMsg2: "18세 이상이어야 합니다",
    mobile: "휴대폰 번호",
    mobile_errMsg: "올바른 휴대폰 번호를 입력하십시오",
    mobile_errMsg2: "전화번호가 이미 등록되어 있습니다",
    emailAddress: "이메일 주소",
    emailAddress_errMsg: "올바른 이메일 주소를 입력하십시오",
    emailAddress_errMsg2: "이메일 주소가 이미 등록되었습니다",
    confirmEmailAddress: "이메일 주소 확인",
    confirmEmailAddress_errMsg: "이메일 주소를 확인하십시오",
    gender: "성별",
    gender_opt1: "성별을 입력해주세요",
    gender_opt2: "남성",
    gender_opt3: "여성",
    gender_errMsg: "성별을 입력해주세요",
    language_preference: "언어 기본 설정",
    language_preference_opt1: "언어 기본 설정을 선택하십시오",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_opt4: "한국어",
    language_preference_errMsg: "언어 기본 설정을 선택하십시오",
    password_format: "비밀번호는 8자 이상의 영숫자와 대문자와 특수 문자(?!@$%&*)를 포함해야 합니다",
    password: "*비밀번호",
    password_errMsg: "비밀번호를 입력해 주십시오",
    confirm_password: "*비밀번호 확인",
    confirm_password_errMsg: "비밀번호를 다시 입력하세요",
    step3_consent_opt: "아래 모든 이용약관에 동의합니다.",
    step3_consent_txt: "• 이용약관에 동의합니다.\n• 개인정보 수집 및 이용에 동의합니다.\n• 마케팅 활동을 목적으로 한 개인정보 수집 및 이용에 동의합니다.\n• 국내 또는 해외의 제 3자에게 개인정보를 제공하는 것에 동의합니다. ",
    tnc_errMsg: "다음 단계로 진행하려면 이용약관에 동의해 주세요",
    step3_title: "아래 확인란을 선택함으로써 본인은 Imperial Treasure Restaurant Group Pte Ltd('ITRG')가 본인이 제공한 개인 데이터 또는 Imperial Treasure Club 사용과 관련된 데이터를 수집, 사용, 저장 및 공개하는 데 동의합니다. Imperial Treasure의 멤버십(이하 '데이터')은 Imperial Treasure와 대한민국 외부 서비스 제공업체와의 관계에서 발생하는 혜택을 포함하여 본인의 멤버십 또는 로열티 프로그램에서 발생하는 혜택을 완전히 누릴 수 있도록 관련 회사 또는 기타 제3자와 본인의 데이터를 공유합니다.",
    step3_q: "임페리얼 트레져로부터 마케팅 목적의 메세지를 받는 것에 동의합니다. (선택)",
    step3_q_opt1: "이메일로 받기",
    step3_q_opt2: "모바일로 받기",
    step4_q_err: "선택해주세요",
    step4_q: "임페리얼트레져 클럽을 알게 된 경로는 무엇입니까?",
    step4_q_opt1: "참조 선택",
    step4_q_opt2: "파라다이스시티 ,임페리얼 트레져 파인 차이니스 퀴진",
    step4_q_opt3: "파라다이스시티 플라자, 임페리얼 트레져 딤섬 앤 스팀보트",
    step4_q_opt16: "지인 소개",
    step4_q_opt17: "소셜 미디어",
    step4_q_opt18: "기타",
    promo_code: "프로모션 코드",
    promo_code_input_msg: "유효한 프로모션 코드를 입력하세요",
    promo_code_err_msg: "올바른 프로모션 코드를 입력하십시오",
    promo_code_re_msg: "프로모션 코드가 유효합니다!",
    total: "총: ",
    refresh: "새로고침",
    captcha: "보안 문자",
    captcha_errMsg: "보안 문자가 올바르지 않습니다",
    cancel: "취소",
    next: "다음 단계",
    back: "뒤로",
    submitForm: "결제 진행",
  },
  aboutUs: {
    aboutUs: "당사 소개",
    txt1: "임페리얼 트레져 레스토랑 그룹",
    txt2: "레스토랑만의 방대한 종류의 중국 음식과 완벽한 서비스로 인해 임페리얼 트레져는 정통 고급 중국 요리의대명사가 되었습니다.  임페리얼 트레져 레스토랑 그룹은 2004 년에 첫 번째 레스토랑을 개설 한 이래로식도락가와 캐주얼 다이너들의 마음을 사로 잡았습니다.",
    txt3: "현재 그룹은 다음과 같이 그룹의 이름을 딴 20개의 레스토랑과 50 개의 수상내역을 보유하고 있습니다: 에서  미쉐린 2 스, Meituan-Dianping Black Pearl Guide에서 2 Diamond 획득,  아시아 최고의 레스토랑.",
    txt4: "임페리얼 트레져는 싱가포르, 상하이, 광저우, 홍콩, 인천, 런던, 파리에 레스토랑이 있습니다.",
  },
  faqs: {
    faqs: "자주 묻는 질문(FAQ)",
    general: "일반규정",
    general_q1: "임페리얼 트레져 클럽은 무엇인가요?",
    general_q1_opt1: "임페리얼 트레져 클럽은 언제든지 임페리얼 트레져 레스토랑에서 식사를 하는 맴버십 여러분에게 전용 특전과 포인트를 제공하는 카드리스 로열티 프로그램입니다.",
    general_q2: "어떻게 임페리얼 트레져 클럽 맴버십이 될 수 있나요?",
    general_q2_opt1: "members.imperialtreasure.co.kr에서 온라인으로 가입하실 수 있습니다.",
    general_q3: "한국 내 임페리얼 트레져 레스토랑은 어디에 있나요?",
    general_q3_opt1: "회원 특전은 임페리얼 트레져에 참여하는 한국 내 레스토랑에서만 유효합니다.",
    general_q3_opt2: "임페리얼 트레져 파인 차이니즈 퀴진",
    general_q3_opt2_1: "파라다이스 시티 메인호텔 1층 | 전화: (82) 32 729 2227",
    general_q3_opt3: "임페리얼 트레져 딤섬 & 스팀보트 레스토랑",
    general_q3_opt3_1: "파라다이스 시티 메인호텔 1층 | 전화: (82) 32 729 5155",
    general_q4: "멤버십은 언제부터 언제까지 유효한가요?",
    general_q4_opt1: "멤버십은 서명한 날로부터 1년 간 유효합니다. 예를 들어, 2023년 8월 17일에 멤버십에 가입했다면 2024년 8월 31일에 멤버십이 종료됩니다.",
    general_q5: "멤버십 종료일은 어떻게 확인할 수 있을까요?",
    general_q5_opt1: "멤버십 종료일은 맴버십 포탈에 로그인해서 확인할 수 있습니다.",
    general_q6: "임페리얼 트레져 클럽 멤버에는 나이 제한이 있나요?",
    general_q6_opt1: "임페리얼 트레져 클럽 맴버십은 18세 이상이어야 합니다.",
    general_q7: "임페리얼 트레져 클럽에 상세정보를 등록해야 하는 이유가 있나요?",
    general_q7_opt1: "https://members.imperialtreasure.co.kr에서 상세정보를 등록해야 계정을 활성화할 수 있습니다. 상세정보를 등록하면 최신 소식과 맴버 전용 프로모션, 포인트, 행사 초대 등을 받을 수 있습니다!",
    general_q8: "개인정보 업데이트는 어디에서 할 수 있나요?",
    general_q8_opt1: "개인정보를 업데이트하거나 검토하려면 members.imperialtreasure.co.kr에서 계정에 로그인하신 후 “프로필”을 클릭하시면 됩니다. 생일을 변경하기 위해 도움이 필요할 경우 kr.club@imperialtreasure.com으로 이메일을 보내주세요.",
    general_q9: "i$나 전자 바우처, 다른 리워드는 어떻게 확인할 수 있나요?",
    general_q9_opt1: "members.imperialtreasure.co.kr에서 로그인하셔서 맴버십 특전과 남은 포인트를 확인할 수 있습니다.",
    general_q10: "비밀번호를 잊었을 때에는 어떻게 해야 하나요?",
    general_q10_opt1: "비밀번호를 재설정하려면 맴버십 포탈 members.imperialtreasure.co.kr에서 “비밀번호 찾기”를 클릭하세요. 화면에 나타나는 지시에 따라 비밀번호를 재설정하세요.",
    general_q11: "맴버십 계정에 관해 질문이 있을 때에는 어떻게 해야 할까요?",
    general_q11_opt1: "맴버십 계정에 대한 질문이 있을 때에는 kr.club@imperialtreasure.com로 이메일을 보내주시기 바랍니다.",
    general_q12: "임페리얼 트레져 클럽 맴버십을 양도할 수 있나요?",
    general_q12_opt1: "아니요. 맴버십은 양도 불가능합니다.",
    general_q13: "맴버십 자격은 어떻게 종료할 수 있나요?",
    general_q13_opt1: "맴버십은 기간이 남았을 때 언제든지 kr.club@imperialtreasure.com으로 이메일을 보내 맴버 자격 종료를 신청할 수 있습니다. 맴버 자격이 종료될 때에 환불이나 사용되지 않은 전자 바우처의 조기 발급은 이루어지지 않는다는 점에 유의하시기 바랍니다. 또는 멤버십 갱신을 하지 않으면 기간이 종료되는 날에 자동으로 맴버 자격이 종료되며, 남은 i$나 사용하지 않은 전자 바우처는 모두 소멸됩니다.",
    membershipTypes: "멤버십 등급",
    membershipTypes_q1: "멤버십 등급은 어떻게 되나요?",
    membershipTypes_q1_opt1: "실버, 골드, 다이아몬드의 세 가지 멤버십 등급이 존재합니다.",
    membershipTypes_q2: "실버/골드/다이아몬드 맴버가 되는 방법은 무엇인가요?",
    membershipTypes_q2_opt1: "실버 맴버가 되려면 초청창을 받거나 온라인으로 등록하면 됩니다.",
    membershipTypes_q2_opt2: "골드 맴버가 되려면 기존 맴버십 기간 내에 누적 1000만 원 이상*을 사용해야 합니다.",
    membershipTypes_q2_opt3: "다이아몬드 맴버가 되려면 기존 맴버십 기간 내에 누적 2500만원 이상*을 사용해야 합니다.",
    membershipTypes_q2_opt4: "*누적 사용금액은 서비스 수수료를 제외한 금액을 기준으로 합니다.",
    membershipTypes_q3: "맴버십 등급을 유지하는 방법은 무엇인가요?",
    membershipTypes_q3_opt1: "골드 등급을 유지하려면 다음 맴버십 자격 갱신 전까지 누적 1000만 원 이상*을 사용하면 됩니다.",
    membershipTypes_q3_opt2: "다이아몬드 등급을 유지하려면 다음 맴버십 자격 갱신 전까지 누적 2500만 원 이상*을 사용하면 됩니다.",
    membershipTypes_q3_opt3: "*누적 사용금액은 서비스 수수료를 제외한 금액을 기준으로 합니다.",
    membership: "맴버십 특전 및 포인트",
    membership_q1: "맴버십 특전은 무엇인가요?",
    sliver: "실버",
    gold: "골드",
    diamond: "다이아몬드",
    complimentary_treats: "맴버십 특전",
    complimentary_treats_1_1: "북경오리 X 1",
    complimentary_treats_1_2: "-",
    complimentary_treats_1_3: "제공",
    complimentary_treats_1_4: "제공",
    complimentary_treats_2_1: "3가지 딤섬 세트 (계절메뉴) X 1",
    complimentary_treats_2_2: "-",
    complimentary_treats_2_3: "제공",
    complimentary_treats_2_4: "-",
    complimentary_treats_3_1: "싱가포르식 칠리 소스 새우 볶음 X 1",
    complimentary_treats_3_2: "-",
    complimentary_treats_3_3: "제공",
    complimentary_treats_3_4: "-",
    complimentary_treats_4_1: "25만원 전자 바우처",
    complimentary_treats_4_2: "-",
    complimentary_treats_4_3: "제공",
    complimentary_treats_4_4: "-",
    complimentary_treats_5_1: "샴페인 X 1",
    complimentary_treats_5_2: "-",
    complimentary_treats_5_3: "-",
    complimentary_treats_5_4: "제공",
    complimentary_treats_6_1: "광어요리 X 1",
    complimentary_treats_6_2: "-",
    complimentary_treats_6_3: "-",
    complimentary_treats_6_4: "제공",
    complimentary_treats_7_1: "50만원 전자 바우처",
    complimentary_treats_7_2: "-",
    complimentary_treats_7_3: "-",
    complimentary_treats_7_4: "제공",
    complimentary_treats_8_1: "3가지 맛 바비큐 X 1",
    complimentary_treats_8_2: "-",
    complimentary_treats_8_3: "-",
    complimentary_treats_8_4: "제공",
    membership_q2: "특전 만료일은 어떻게 알 수 있나요?",
    membership_q2_opt1: "특전 만료일을 알고 싶다면 맴버십 포탈 members.imperialtreasure.co.kr에 로그인하신 후 '바우처' 메뉴에서 상세 내용을 확인하시면 됩니다.",
    membership_q3: "임페리얼 트레져 클럽 맴버인지 확인하는 방법은 무엇인가요?",
    membership_q3_1_1: "맴버십 자격을 확인하고자 하면 저희 직원에게 휴대전화 번호를 알려주시면 됩니다.",
    membership_q4: "맴버십 특전을 즐기는 방법은 무엇인가요?",
    membership_q4_1_1: "요리/샴페인을 제공받고자 하실 경우에는 하루 전날 주문하셔야 합니다. 샴페인과 요리는 임페리얼 트레져 파인 차이니즈 퀴진에서 제공됩니다. 전자 바우처를 이용하고자 하신다면 계산 전에 직원에게 말씀해주시기 바랍니다. 맴버십 특전을 사용하고자 하실 때에는 반드시 맴버 본인이 오셔야 합니다 (신분증 필요).",
    membership_q5: "법인 고객이 임페리얼 트레져 클럽 멤버십을 통해 법인 할인을 받을 수 있나요?",
    membership_q5_opt1: "아니요. 법인 할인은 임페리얼 트레져 클럽 멤버십과 동시에 적용할 수 없습니다. 법인 고객일 경우, 매 결제 마다 법인 할인과 임페리얼 트레져 클럽 멤버십 적용 중 하나를 선택하셔야 합니다.",
    membership_q6: "임페리얼 트레져 클럽 맴버십 특전을 임페리얼 트레져 레스토랑이 있는 다른 나라에서도 사용할 수 있나요?",
    membership_q6_opt1: "임페리얼 트레져 레스토랑이라면 세계 어디서든 i$를 적립하실 수 있습니다. i$ 환전은 등록하셨던 국가를 기준으로, 임페리얼 트레져에서 정한 환율에 따라 이루어집니다. 전자 바우처는 등록하셨던 국가에서만 사용할 수 있습니다.",
    membership_q7: "임페리얼 트레져 클럽 맴버십을 양도할 수 있나요?",
    membership_q7_opt1: "아니요. 맴버십은 양도 불가능합니다.",
    earning: "I달러 (i$)의 적립 및 사용",
    earning_q1: "i$는 어떻게 적립하나요?",
    earning_q1_opt1: "임페리얼 트레져 레스토랑에서 1만원*을 사용하실 때마다 i$1이 적립됩니다 (*서비스 수수료, 관부가세, 전자 바우처, 임페리얼 트레져 식사 바우처 및 행사 항목의 구매 또는 이용 제외). 식사하실 때에는 맴버 본인이 반드시 참석해야 합니다 (신분증 필요). 날짜가 지난 이전 영수증에 대한 i$ 적립은 인정되지 않습니다. 거래 내역과 전자 바우처, 새로 적립된 i$가 시스템에 반영되려면 최대 1일이 소요될 수 있습니다. i$ 적립을 위해 다수의 맴버십 계정을 복합적으로 사용하거나 청구서 금액을 나누어 결제하는 행위는 금지됩니다. 적립된 i$ 잔액은 멤버십 종료 시 함께 소멸됩니다. 다만 멤버십 만료 시 적립된 i$ 잔액은 3개월 간 유지되며, 이 기간 내에 5만원 전자 바우처로 전환할 수 있습니다. 전환되지 않은 포인트는 소멸됩니다.",
    earning_q2: "i$를 사용하는 방법은 무엇입니까?",
    earning_q2_opt1: "맴버는 i$50을 적립할 때마다 자동으로 맴버십 기간 내에 사용할 수 있는 5만원 전자 바우처를 받게 됩니다*. 5만원 전자 바우처는 지정된 레스토랑에서 식사비용을 결제하실 때에 사용할 수 있습니다. 맴버십 특전을 사용하고자 하실 경우, 맴버 본인이 직접 오셔야 합니다 (신분증 필요). 맴버는 특전을 사용했음을 확인하기 위해 반드시 영수증에 서명해야 합니다. 맴버십 특전을 사용하기 위해 다수의 맴버십 계정을 복합적으로 사용하거나 청구서 금액을 나누어 결제하는 행위는 금지됩니다.\n(*시스템이 지정된 날에 자동으로 i$를 전환합니다)",
    earning_q3: "온라인 몰에서 구매하면 i$를 적립하거나 맴버십 특전을 사용할 수 있나요?",
    earning_q3_opt1: "아니요. i$ 적립이나 맴버십 특전 사용은 별도로 명시하지 않은 한 지정된 레스토랑에서 구매하는 경우에만 적용됩니다.",
    earning_q4: "i$가 소멸될 수 있나요?",
    earning_q4_opt1: "예. 적립된 i$는 멤버십 기간 동안에만 유효하며, 멤버십이 만료되면 함께 소멸됩니다. 다만 멤버십 만료 시 적립되어 있던 i$ 잔액은 3개월 간 유지되며, 이 기간 내에 5만원 전자 바우처로 전환할 수 있습니다. 전환되지 않은 포인트는 소멸됩니다.",
    earning_q5: "구매하면 바로 i$에 반영되나요?",
    earning_q5_opt1: "예. 시스템이 적립된 i$를 바로 반영합니다. 다만 예상치 못한 상황으로 네트워크에 지연이 발생할 수도 있으므로 어느 정도의 시간을 두고 확인하실 것을 권장합니다.",
    earning_q6: "i$를 적립하거나 맴버십 특전을 사용하기 위해 다수의 맴버 계정을 사용할 수 있나요?",
    earning_q6_opt1: "아니요. 맴버십 특전을 사용하기 위해 다수의 맴버 계정을 복합적으로 사용하는 행위는 금지됩니다.",
    earning_q7: "i$를 적립하거나 맴버십 특전을 사용하기 위해 청구 금액을 분할할 수 있나요?",
    earning_q7_opt1: "아니요. 맴버십 특전을 사용하기 위해 청구서 금액을 나누어 결제하는 행위는 금지됩니다.",
    earning_q8: "맴버 본인이 없어도 맴버의 가족이나 친구가 i$를 적립하거나 맴버십 특전을 사용할 수 있나요?",
    earning_q8_opt1: "아니요. 맴버십 특전을 사용하고자 하신다면 맴버 본인이 직접 오셔야 합니다.",
    earning_q9: "제3자 플랫폼을 통한 온라인 주문으로 i$를 적립하거나 맴버십 특전을 사용할 수 있나요?",
    earning_q9_opt1: "아니요. 제3자 플랫폼을 통한 온라인 주문에는 i$ 적립이나 맴버십 특전이 적용되지 않습니다.",
  },
  tnc: {
    title: "Terms And Privacy",
    membership: "Membership Terms And Privacy",
    membership_1_1: "1.1 Introduction",
    membership_1_1_1: 'Imperial Treasure South Korea Co. Ltd and Imperial Treasure F&B Management Pte. Ltd.(“Imperial Treasure”) would like to offer our privileged customers news and information regarding our products and services, including promotions, special offers and discounts.  We are offering you the opportunity to become a member of the Imperial Treasure Club (a "Member") to enjoy special privileges that Imperial Treasure may offer from time to time.',
    membership_1_2: "1.2 Collection and use of personal data",
    membership_1_2_1: "If you become a Member, you will need to provide Imperial Treasure certain personal information (including your name and contact details such as email address and phone number).  You will need to provide us with your mobile number for us to identify you. Your mobile number will be your username or unique identifier as a member of Imperial Treasure Club. Any personal data held by us about you will be held in accordance with our Privacy Notice.",
    membership_1_3: "1.3 Direct marketing",
    membership_1_3_1: 'By clicking on the "SUBMIT" button below, you agree that Imperial Treasure, our affiliates, related entities, and third parties acting on our behalf ("Business Partners"), may collect, use and disclose your personal data, which you have provided at the member\'s portal at https://members.imperialtreasure.com.kr (the "Member\'s Portal"), for providing marketing material that you have agreed to receive, in accordance with the Personal Information Protection Act (“PIPA”) and our Privacy Notice. Please note that marketing material may be sent directly by our Business Partners, and we may disclose your personal data to them for that purpose.',
    membership_1_4: "1.4 Membership Tiers",
    membership_1_4_1: "1.4.1 Membership registration, i-dollars, and Cash e-Vouchers",
    membership_1_4_1_1: "Where you sign up to become a Member, you will be required to provide your mobile number, among other information we may require from you, in order to sign up for the membership. This may be required online or by our restaurant staff depending on the circumstances.",
    membership_1_4_1_2: "Membership is strictly non-transferable and shall only be enjoyed by the Member in person.",
    membership_1_4_1_3: "As a Member, you earn one (01) i-dollar at the rate specified on the membership programme website [Link:Members.imperialtreasure.co.kr/benefits] for the country in which you signed up, when you spend at any participating Imperial Treasure restaurants (excluding service charge, tax, Cash e-vouchers used, and the purchase and use of Imperial Treasure gift vouchers). Please refer to the Member’s Portal for a list of participating Imperial Treasure restaurants. Member must be physically present at the point of dining and provide the registered mobile number to the service staff in order to earn i-dollars. Earning of i-dollars must be made at the point of transaction. Requests on i-dollar accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, Cash e-vouchers and i-dollar earned. I-dollars accrued shall expire upon expiry of membership. Any i-dollar accrued is however granted a three (03) months grace period and must be converted to Cash e-Vouchers within this grace period, failing which such i-dollar shall be deemed forfeited. Transactions made via third party vendors are not eligible for earning of i-dollars.",
    membership_1_4_1_4: "Earned i-dollars are converted in the Member Portal into Imperial Treasure e-vouchers of a specified value (“Cash e-Vouchers”) at the rate specified on the membership programme website [Link:Members.imperialtreasure.co.kr/benefits] for the country in which you signed up. Each Cash e-Voucher is valid for twelve (12) months from date of issue and valid for use within the membership period only. The Cash e-Voucher can be used to offset the bill when dining at any participating Imperial Treasure restaurants, subject to Imperial Treasure’s sole discretion and any other terms and conditions stated at [https://members.imperialtreasure.co.kr]. Neither i-dollars nor Cash e-Vouchers have any cash value and cannot be converted or redeemed for cash.",
    membership_1_4_2: "1.4.2 Membership Tiers",
    membership_1_4_2_1: "Unless otherwise determined by Imperial Treasure in its sole discretion, Silver Tier status is conferred on Members when they are invited to first join the membership programme and is valid for the period specified on the membership programme website [Link:https://members.imperialtreasure.co.kr] for the country in which you signed up. At the end of this period, if the Member has not been upgraded to the Gold Tier or Diamond Tier, the Member may be required to pay such renewal fee or meet the relevant renewal requirements as may be prescribed by Imperial Treasure in its sole discretion on the membership website [Link: https://members.imperialtreasure.co.kr] for the country in which you signed up. Imperial Treasure will give a reasonable advance notice to relevant Members thirty (30) days prior to the end of the membership programme period.",
    membership_1_4_2_2: "Gold Tier status is conferred on Members who spend at least the amount set out for Gold Tier on the membership programme website [Link: https://members.imperialtreasure.co.kr] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Gold Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Gold Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Silver Tier status.",
    membership_1_4_2_3: "Diamond Tier status is conferred on Members who spend at least the amount set out for Diamond Tier on the membership programme website [Link: https://members.imperialtreasure.co.kr] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Diamond Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Diamond Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Gold Tier or Silver Tier status accordingly.",
    membership_1_4_2_4: "Imperial Treasure may, in its sole discretion, alter, limit, or modify the Silver Tier, Gold Tier and/or Diamond Tier program rules, regulations, benefits, eligibility for membership, or any other feature of the Silver Tier, Gold Tier and/or Diamond Tier programme and/or may terminate the Silver Tier, Gold Tier and/or Diamond Tier programme at any time in its sole discretion, by giving reasonable advance notice of at least [30] days on the membership programme website and/or by email. Please check the membership programme website regularly for updates.",
    membership_1_4_3: "1.4.3 Membership Tier Rewards",
    membership_1_4_3_1: "Each membership tier also entitles Members to certain rewards and privileges, which may include other e-vouchers, free gifts and/or other dining benefits (“Rewards”). The current tier privileges are as set out in the membership programme website [Link: https://members.imperialtreasure.co.kr]. The validity and terms and conditions of such Rewards are as set out in [Link: Members.imperialtreasure.co.kr/tnc], which may be amended by Imperial Treasure at any time in its sole discretion, by giving reasonable notice on the membership programme website and/or by email. Rewards are always subject to availability. Imperial Treasure reserves the right to replace a Reward with another Reward of equivalent or similar value. Rewards do not have any cash value and cannot be converted or redeemed for cash.",
    membership_1_4_4: "1.4.4 Redemption of Rewards and Cash e-Vouchers",
    membership_1_4_4_1: "Member must be physically present (proof of identification required) at the point of dining in order to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made. Redemption of Rewards are subject to prevailing local law requirements.",
    membership_1_4_4_2: "Combining multiple membership accounts, combining bills or splitting the bills to earn i-dollars and/or redeem Rewards and membership privileges is strictly not allowed. Membership must be active at the point of redemption.",
    membership_1_5: "1.5 Amending personal data and terminating membership",
    membership_1_5_1: "You may amend your personal data or marketing preferences at any time via our Member’s Portal. You will need to inform us in writing at kr.club@imperialtreasure.com should you choose to terminate your membership. Please allow a few working days for changes to take effect. Kindly be advised that there will be no refunds made and/or early issuance of any unused Cash e-vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and all balance i-dollars and/or unused Cash e-vouchers will be deemed forfeited.",
    membership_1_6: "1.6 Termination and cancellation",
    membership_1_6_1: "We reserve the right to terminate the Member's membership if the membership account is found to be shared with another non-Member. We reserve the right to terminate your membership, in our absolute discretion, should you abuse any of the conditions and privileges bestowed on you as a Member.",
    membership_2: "2. Law and forum",
    membership_2_1: "These Terms, including all policies and additional terms referenced herein (“Membership Agreement”),  are governed by and construed in accordance with the laws of South Korea.",
    membership_2_2: "Any dispute or claim arising out of or in connection with this Membership Agreement, (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of South Korean courts.",
    membership_3: "3. Consent",
    membership_3_agree_all_terms: "□ I agree to all terms and conditions below",
    membership_3_agree_tnc: "□ I agree to the Terms and Conditions",
    membership_3_agree_tnc_link: "[Link: Members.imperialtreasure.co.kr/tnc ] (Required)",
    membership_3_agree_personal_info: "□ I agree to the collection and use of my personal information (Required)",
    membership_3_scroll_box: "[SCROLL BOX]",
    membership_3_purpose_of_collection: "Purpose of Collection and Use",
    membership_3_purpose_of_collection_1: "For restaurant and membership related processing",
    membership_3_personal_info: "Personal Information to be Collected",
    membership_3_personal_info_1: "Name, DOB, email, mobile",
    membership_3_retention_period: "Retention period",
    membership_3_retention_period_1: "Until membership cancellation",
    membership_3_agree_personal_info_mkt: "□ I agree to the collection and use of my personal information for marketing purposes (Optional)",
    membership_3_purpose_of_collection_2: "To provide you with marketing and promotional material and other information about our products (including promotions and special offers), as well as other products and services provided by our affiliates and partners",
    membership_3_personal_info_1: "Name, DOB, email, mobile",
    membership_3_retention_period_1: "Until membership cancellation",
    membership_3_agree_personal_info_third_parties: "□ I agree to the transfer of my personal information to third parties either in Korea or overseas (Optional)",
    membership_3_personal_data_trf: "1. For personal data transfer within Korea",
    membership_3_transferee: "Transferee",
    membership_3_transferee_1: "Imperial Treasure F&B Management Pte. Ltd.",
    membership_3_personal_info_tra: "Personal Information to be Transferred",
    membership_3_personal_info_tra_1: "Name, DOB, email, mobile, membership tier",
    membership_3_purpose_of_use: "Purpose of Use of Personal Information by the Transferee",
    membership_3_purpose_of_use_1: "For restaurant and membership related processing",
    membership_3_transferee_retention_period: "Transferee’s Retention Period",
    membership_3_transferee_retention_period_1: "Until membership cancellation",
    membership_3_personal_data_trf_outside: "2. For personal data transfer outside of Korea",
    membership_3_personal_data_trf_outside_transferee: "Transferee",
    membership_3_personal_data_trf_outside_transferee_1_1: "Xgate Pte Ltd data.privacy@xgate.com",
    membership_3_personal_data_trf_outside_transferee_1_2: "- Imperial Treasure Restaurant Group Pte Ltd \n- Imperial Treasure F & B Management Pte Ltd \n-  Imperial Treasure (Teochew Cuisine) Pte Ltd \n- Imperial Treasure Nan Bei Restaurant Pte Ltd \n- Imperial Treasure Noodle House Pte Ltd \n- Imperial Treasure Cantonese Cuisine Pte Ltd \n- Imperial Treasure Fine Dining Restaurant Pte Ltd \n- Imperial Treasure Windows of Hong Kong Pte Ltd \n- Imperial Treasure Steamboat Pte Ltd \n- Imperial Treasure Shanghai Cuisine Pte Ltd \n- Imperial Treasure F&B Shanghai Co., Ltd \n- Imperial Treasure Xin Zhou F&B Management Co Ltd \n- Imperial Treasure F&B Management Shanghai Co., Ltd \n- Imperial Treasure (Guangzhou) Co., Ltd. \n- Imperial Treasure (International) Co., Ltd. \n- Imperial Treasure South Korea Co., Ltd \n- Imperial Treasure F & B Management Pte Ltd \n- Imperial Treasure (UK) Co., Ltd \n- Imperial Treasure (France) \n- dpo@imperialtreasure.com",
    membership_3_personal_data_trf_outside_time_and_method: "Time and Method of Transfer",
    membership_3_personal_data_trf_outside_time_and_method_1_1: "Real-time / Online transfer",
    membership_3_personal_data_trf_outside_time_and_method_1_2: "Real-time / Online transfer",
    membership_3_personal_data_trf_outside_personal_info_tra: "Personal Information to be Transferred",
    membership_3_personal_data_trf_outside_personal_info_tra_1_1: "Name, DOB, email, mobile, membership tier",
    membership_3_personal_data_trf_outside_personal_info_tra_1_2: "Name, DOB, email, mobile, membership tier",
    membership_3_personal_data_trf_outside_purpose_of_use: "Purpose of Use of Personal Information by the Transferee",
    membership_3_personal_data_trf_outside_purpose_of_use_1_1: "Membership related benefits",
    membership_3_personal_data_trf_outside_purpose_of_use_1_2: "Membership related benefits",
    membership_3_personal_data_trf_outside_transferee_retention_period: "Transferee’s Retention Period",
    membership_3_personal_data_trf_outside_transferee_retention_period_1_1: "Until membership cancellation",
    membership_3_personal_data_trf_outside_transferee_retention_period_1_2: "Until membership cancellation",
    membership_3_agree_receive_mkt_msg: "□ I agree to receive marketing messages from Imperial Treasure (Optional)",
    privacy: "Privacy Notice",
    privacy_1: "1. INTRODUCTION",
    privacy_1_1: 'Imperial Treasure South Korea Co. Ltd (collectively referred as "Imperial Treasure", "us", "we") is committed to protect the privacy of visitors to this website (the "Website"). This Privacy Notice sets out how Imperial Treasure collects, uses or discloses personal data that you have provided to us through the Website. By visiting the Website you are accepting and consenting to the collection, use and disclosure of personal data described in this Privacy Notice. If you do not agree with this Privacy Notice, please do not use the Website. This Privacy Notice does not apply to other websites to which we link ("Third Party Website" or "Third Party Websites").',
    privacy_1_2: "This Privacy Notice also describes your data protection rights, including a right to object to some of the processing which Imperial Treasure carries out. More information about your rights, and how to exercise them, is set out in the following section: “9. What rights do I have”.",
    privacy_1_3: 'Maintaining the privacy of our customers’ information is important to us. We do not disclose rent or sell our customers’ personal data to third parties (except as described in "6. Disclosures of personal data to third parties").',
    privacy_2: "2. COLLECTION AND USE OF PERSONAL DATA",
    privacy_2_1: "We collect the following personal data when you register as a user of the Website and make purchases through the Website:",
    privacy_2_1_1: "email address",
    privacy_2_1_2: "your name, address and phone number (including delivery addresses if different);",
    privacy_2_1_3: "billing address, including your name, address and phone number; and",
    privacy_2_1_4: "any other personal data provided to us when you submit any comment, question or feedback to us, request services from us, or disclose your personal data to us in any way",
    privacy_2_2: "We use your personal data for the following purposes:",
    privacy_2_2_1: "To fulfil a contract, or take steps linked to a contract: this is relevant where you make a purchase from us. This includes:",
    privacy_2_2_1_1: "to fulfil your order and deliver it to you;",
    privacy_2_2_1_2: "to facilitate and track your purchases from us (including in our restaurants or via our websites); and",
    privacy_2_2_1_3: "to provide you with service support;",
    privacy_2_2_2: "As required by Imperial Treasure to conduct our business and pursue our legitimate interest, in particular:",
    privacy_2_2_2_1: "to facilitate your registration as a user of the Website;",
    privacy_2_2_2_2: "to use information you provide to provide products and services you have requested;",
    privacy_2_2_2_3: "(if you use a card payment for any purchase on the Website) we use third parties to check the validity of the sort code, account number and card number you submit in order to prevent fraud; and;",
    privacy_2_2_2_4: "to respond to comments, queries and feedback.",
    privacy_2_2_3: "Where you give us consent:",
    privacy_2_2_3_1: "we provide you with marketing and promotional material and other information about our products (including promotions and special offers), as well as other products and services provided by our affiliates and partners and",
    privacy_2_2_3_2: "on other occasions where we ask you for consent, we will use the data for the purposes which we explain at that time.",
    privacy_2_2_4: "For purposes which are required by law: in response to requests by government or law enforcement authorities conducting an investigation.",
    privacy_2_3: "The provision of your name, family name, contact number, date of birth, country code and email address are mandatory so that we can contact you and fulfil any of your order(s): if any required data is not provided, then we will not be able to fulfil your order(s). All other provision of your information is optional.",
    privacy_2_4: "Additionally, through the Website, we may gather certain aggregated and anonymous information about the use of the Website, such as how frequently certain areas of the Website are visited, including through the use of cookies, web beacons, Google Analytics and other technologies.",
    privacy_2_5: "We may use cookies, web beacons, Google Analytics and other technologies for the following purposes:",
    privacy_2_5_1: "to identify the causes of problems arising at web servers and to resolve these problems;",
    privacy_2_5_2: "to improve the contents of the Website;",
    privacy_2_5_3: "to customize the content of the Website and our emails to suit your individual interests or purposes and selectively display best-matched pages to you, including tracking your past purchases in order to provide you with new services or special promotions;",
    privacy_2_5_4: "to utilize your browsing history on the Website for demographic research; and",
    privacy_2_5_5: "to obtain static data of web visitors and aggregated website visitation statistics which are anonymous in nature.",
    privacy_2_6: "You may reject our use of cookies by configuring your browser to disable the use of cookies. However, you may no longer be able to receive services or personalized features of the Website which rely on the use of cookies.",
    privacy_3: "3. SENDING OF EMAILS TO MEMBERS",
    privacy_3_1: "Genuine emails from Imperial Treasure always will come from an address ending @imperialtreasure.com. Do not open any attachments or click any links from suspicious e-mails. If you wish to report a suspicious email claiming to be from Imperial Treasure that you believe is a forgery, you may email to kr.marketing@imperialtreasure.com",
    privacy_4: "4. RELYING ON OUR LEGITIMATE INTERESTS",
    privacy_4_1: "We have carried out balancing tests for all the data processing we carry out on the basis of our legitimate interests, which we have described above. You can obtain information on any of our balancing tests by contacting us using the details set out later in this notice.",
    privacy_5: "5. WITHDRAWING CONSENT OR OTHERWISE OBJECTING TO DIRECT MARKETING",
    privacy_5_1: "Wherever we rely on your consent, you will always be able to withdraw that consent, although we may have other legal grounds for processing your data for other purposes, such as those set out above. In some cases, we can send you direct marketing without your consent, where we rely on our legitimate interests.  You have an absolute right to opt-out of direct marketing, or profiling we carry out for direct marketing, at any time. You can do this by following the instructions in the communication where this is an electronic message, or by contacting us using the details set out below.",
    privacy_6: "6. DISCLOSURES OF PERSONAL DATA TO THIRD PARTIES",
    privacy_6_1: "Your personal data may also be transferred out of South Korea from time to time. Such transfers will be carried out in accordance with the PIPA. We will not provide your personal data to a third party without your prior consent.",
    privacy_6_2: "List of third parties to which personal data is provided:",
    privacy_6_2_a: "1. For personal data transfer within Korea",
    privacy_6_2_1: "Transferee",
    privacy_6_2_1_1: "Imperial Treasure F&B Management Pte. Ltd.",
    privacy_6_2_2: "Personal Information to be Transferred",
    privacy_6_2_2_1: "Name, DOB, email, mobile",
    privacy_6_2_3: "Purpose of Use of Personal Information by the Transferee",
    privacy_6_2_3_1: "For restaurant and membership related processing",
    privacy_6_2_4: "Transferee’s Retention Period",
    privacy_6_2_4_1: "Until membership cancellation",
    privacy_6_3_a: "2. For personal data transfer outside of Korea",
    privacy_6_3_1: "Name of Transferee\n(Privacy Contact)",
    privacy_6_3_1_1: "Xgate Pte Ltd\ndata.privacy@xgate.com",
    privacy_6_3_1_2: "- Imperial Treasure Restaurant Group Pte Ltd \n- Imperial Treasure F & B Management Pte Ltd\n- Imperial Treasure (Teochew Cuisine) Pte Ltd\n- Imperial Treasure Nan Bei Restaurant Pte Ltd\n- Imperial Treasure Noodle House Pte Ltd\n- Imperial Treasure Cantonese Cuisine Pte Ltd\n- Imperial Treasure Fine Dining Restaurant Pte Ltd\n- Imperial Treasure Windows of Hong Kong Pte Ltd\n- Imperial Treasure Steamboat Pte Ltd\n- Imperial Treasure Shanghai Cuisine Pte Ltd\n- Imperial Treasure F&B Shanghai Co., Ltd\n- Imperial Treasure Xin Zhou F&B Management Co Ltd\n- Imperial Treasure F&B Management Shanghai Co., Ltd\n- Imperial Treasure (Guangzhou) Co., Ltd.\n- Imperial Treasure (International) Co., Ltd.\n- Imperial Treasure South Korea Co., Ltd\n- Imperial Treasure F & B Management Pte Ltd\n- Imperial Treasure (UK) Co., Ltd",
    privacy_6_3_2: "Time and Method of Transfer",
    privacy_6_3_2_1: "Real-time / Online transfer",
    privacy_6_3_2_2: "Real-time / Online transfer",
    privacy_6_3_3: "Personal Information to be Transferred",
    privacy_6_3_3_1: "Name, DOB, email, mobile, membership tier",
    privacy_6_3_3_2: "Name, DOB, email, mobile, membership tier",
    privacy_6_3_4: "Purpose of Use of Personal Information by the Transferee",
    privacy_6_3_4_1: "Membership related benefits",
    privacy_6_3_4_2: "Membership related benefits",
    privacy_6_3_5: "Transferee’s Retention Period",
    privacy_6_3_5_1: "Until membership cancellation",
    privacy_6_3_5_2: "Until membership cancellation",
    privacy_6_4: "List of entrustees to which personal information is provided:",
    privacy_6_4_a: "1. For personal data transfer within Korea",
    privacy_6_4_1: "Transferee",
    privacy_6_4_1_1: "Imperial Treasure F&B Management Pte. Ltd.",
    privacy_6_4_2: "Personal Information to be Transferred",
    privacy_6_4_2_1: "Name, DOB, email, mobile, membership tier",
    privacy_6_4_3: "Purpose of Use of Personal Information by the Transferee",
    privacy_6_4_3_1: "CRM loyalty programme",
    privacy_6_4_4: "Transferee’s Retention Period",
    privacy_6_4_4_1: "Until membership cancellation",
    privacy_6_5: "Where information is transferred outside the EEA, and where this is to a stakeholder or vendor in a country that is not subject to an adequacy decision by the EU Commission, data is adequately protected by EU Commission approved standard contractual clauses, or a vendor's Processor Binding Corporate Rules.  A copy of the relevant mechanism can be provided for your review on request to our Data Protection Office (please refer to the section below: '12 Contact Us').",
    privacy_7: "7. HOW WE PROTECT YOUR INFORMATION",
    privacy_7_1: "We are committed to protecting the information we receive from you. We adopt reasonable physical, technical and administrative practices to help protect the confidentiality, security and integrity of data, including personal data, stored on our system. We believe the measures we have implemented reduce the likelihood of security problems to a level appropriate to the type of data involved. Although we take reasonable steps to protect the information we receive from you, no computer system is completely secure and we cannot be held liable for any loss you may suffer for unauthorized access or loss of any data provided to the Website.",
    privacy_8: "8. LINKS TO THIRD PARTY SITES",
    privacy_8_1: "The Website may contain links to third party websites and microsites, including third party payment gateways, whose privacy practices may differ from those of ours. If you submit personal data, including payment information, to any of those websites, that personal data is not subject to this Privacy Notice and will be subject to the third party's privacy practices instead.",
    privacy_8_2: "We therefore encourage you to review the privacy statement of any website you visit. By clicking on or activating such links and leaving the Website, we can no longer exercise control over any data or information which you give to any other entity after leaving the Website, and any access to such other websites is entirely at your own risk.",
    privacy_9: "9. WHAT RIGHTS DO I HAVE",
    privacy_9_1: "You have the right to ask us for a copy of your personal data; to correct, delete or restrict (stop any active) processing of your personal data; and to obtain the personal data you provide to us for a contract or with your consent in a structured, machine readable format, and to ask us to share (port) this data to another data controller.",
    privacy_9_2: "In addition, you can object to the processing of your personal data in some circumstances (in particular, where we don’t have to process the data to meet a contractual or other legal requirement, or where we are using the data for direct marketing).",
    privacy_9_3: "These rights may be limited, for example if fulfilling your request would reveal personal data about another person, where they would infringe the rights of a third party (including our rights) or if you ask us to delete information which we are required by law to keep or have compelling legitimate interests in keeping. Relevant exemptions are included in the applicable laws and regulations. We will inform you of relevant exemptions we rely upon when responding to any request you make.",
    privacy_9_4: "If you are based in the EU and have unresolved concerns, you have the right to complain to an EU data protection authority where you live, work or where you believe a breach may have occurred.",
    privacy_9_5: 'To exercise any of these rights, or to obtain other information, such as a copy of a legitimate interests balancing test, you can get in touch with us – by contacting our Data Protection Manager (See "12 Contact us" below).  To correct your personal data, you can do so by signing into your user account and updating your personal data. We will only disclose personal data in accordance with an access request to the individual who registered his/her personal data with the Website, and only after confirming that the person requesting access is indeed the registered party.',
    privacy_9_6: "You may also contact our Data Protection Manger to withdraw your consent to our use of your personal data.",
    privacy_9_7: "Please allow ten (10) working days for your request to be processed.",
    privacy_10: "10. RETENTION OF INFORMATION",
    privacy_10_1: "Where we process registration data, we do this for as long as you are an active user of the Website and for six (6) years after this.",
    privacy_10_2: "Where we process personal data for marketing purposes or with your consent, we will process such data until you ask us to stop and for a short period after this (to allow us to implement your requests). We also keep a record of the fact that you have asked us not to send you direct marketing or not to process your data so that we can respect your request in the future.",
    privacy_10_3: "Where we process personal data in connection with performing a contract, we keep such data for six (6) years from your last interaction with us.",
    privacy_10_4: "If you withdraw your consent to our collection, use or disclosure of your personal data, we may not be able to provide you with services that require such personal data. Please also note that your personal data may still be retained on our database to the extent permitted by law.",
    privacy_11: "11. PRIVACY POLICY CHANGES",
    privacy_11_1: "If we decide to change our Privacy Notice for the Website, we will post the revised Privacy Notice here and, if appropriate, notify you by email. Please check back frequently to see any updates or changes to our Privacy Notice.",
    privacy_12: "12. CONTACT US",
    privacy_12_1: "If you have any questions about this Privacy Notice, please contact our Data Protection Manager at DPO@imperialtreasure.com.",
  },
  outlets: {
    outlets: "참여 레스토랑",
    restaurant_name: "레스토랑 이름",
    address: "주소",
    contact: "연락처",
    email: "이메일",
    outlets_1_1: "임페리얼트레져 파인 차이니즈 퀴진 레스토랑",
    outlets_1_2: "인천광역시 중구 영종해안남로 321번길 186 \n파라다이스시티 메인호텔 1층",
    outlets_1_3: "(82) 32 729 2227",
    outlets_1_4: "finechinese_pcity@imperialtreasure.com",
    outlets_2_1: "임페리얼 트레져 딤섬 & 스팀보트 레스토랑",
    outlets_2_2: "인천광역시 중구 영종해안남로 321번길 186 \n파라다이스시티 플라자 1층",
    outlets_2_3: "(+65) 6732 7838",
    outlets_2_4: "dimsumsteamboat_pcity@imperialtreasure.com",
  },
  privilege: {
    title: "특전",
    privilege: "맴버십 특전안내",
    privelege_txt: "임페리얼 트레져 클럽에 참여한 레스토랑에서 사용하신 금액 1만원* 당 i$1을 적립해드립니다.\ni$50을 적립하시면 참여 레스토랑에서 사용하실 수 있는 5만원의 전자 바우처를 받을 수 있습니다.",
    silver: "실버",
    silver_1: "초대 코드를 통해 가입됩니다.",
    gold: "골드",
    gold_1: "맴버십 기간 내에 누적 1000만원 이상을 사용하면 골드 회원으로 업그레이드 됩니다.",
    gold_2: "25만원 전자 바우처 X1",
    gold_3: "식사권: \n북경오리 X 1 \n3가지 딤섬(씨우마이, 하까우, 새우 창펀) X 1 \n싱가포르식 칠리 소스 새우 볶음 X 1",
    diamond: "다이아몬드",
    diamond_1: "맴버십 기간 내에 누적 2500만원 이상을 사용하면 다이아몬드 회원으로 업그레이드 됩니다.",
    diamond_2: "50만원 전자 바우처 X 1",
    diamond_3: "식사권: \n북경오리 X 1 \n샴페인 X 1 \n광어요리 X 1 \n3가지 맛 바비큐 X 1",
    remark: "*부가세, 할인, 전자 상품권 상환 및 명절 상품 구매는 제외됩니다.",
    sign_up_now: "지금 가입하세요",
  },
  resetPassword: {
    title: "비밀번호 분실",
    reset_password: "비밀번호 재설정",
    email: "*이메일을 입력하세요",
    email_input: "이메일을 입력하세요",
    email_errMsg: "유효한 이메일을 입력하세요",
    mobile: "*휴대폰 번호를 입력하세요",
    mobile_errMsg: "올바른 휴대폰 번호를 입력하십시오",
    new_password: "*신규 비밀번호",
    new_password_errMsg1: "비밀번호를 입력하세요",
    new_password_errMsg2: "비밀번호는 8자 이상의 영숫자와 대문자와 특수 문자(?!@$%&*)를 포함해야 합니다",
    confirm_password: "*신규 비밀번호 확인",
    confirm_password_errMsg: "비밀번호 확인하십시오",
    reset_password2: "비밀번호 재설정",
    has_been_reset: "비밀번호 재설정되었습니다!",
    login: "로그인",
  },
  forgetPassword: {
    title: "비밀번호 찾기",
    reset_password: "비밀번호 찾기",
    forgot_password: "비밀번호를 잊으셨나요?",
    reset_pw_method: "",
    by_mobile: "모바일로 찾기",
    by_email: "이메일로 찾기",
    email: "*이메일을 입력하세요",
    email_input: "이메일을 입력하세요",
    email_errMsg: "유효한 이메일을 입력하세요",
    mobile: "*휴대폰 번호를 입력하세요",
    mobile_errMsg: "올바른 휴대폰 번호를 입력하십시오",
    reset_password2: "비밀번호 재설정",
    reset_password_sent_email: "비밀번호 재설정 링크가 이메일로 전송되었습니다.",
    reset_password_sent_mobile: "비밀번호 재설정 링크가 모바일로 전송되었습니다.",
    next: "다음",
    back: "뒤로가기",
  },
  updateEmail: {
    title: "이메일 주소 변경",
    member_profile: "멤버 프로필",
    updateEmail: "이메일 주소 변경",
    new_email: "*신규 이메일 주소",
    sent: "전송",
    send_token: "인증 코드 보내기",
    email_errMsg1: "올바른 이메일 주소를 입력하십시오",
    email_errMsg2: "이메일 주소가 이미 등록되었습니다",
    token: "*인증 코드",
    token_error: "인증 코드 실수",
    update: "업데이트",
    update_success: "업데이트 성공!",
    done: "완료",
    Msg1: "휴대폰 번호와 이메일 주소가 모두 이미 사용되었습니다",
    Msg2: "이미 사용된 이메일 주소",
  },
  updateMobile: {
    title: "휴대폰 번호 변경",
    member_profile: "멤버 프로필",
    updateMobile: "휴대폰 번호 변경",
    new_mobile: "*신규 휴대폰 번호",
    sent: "전송",
    send_token: "인증 코드 보내기",
    mobile_errMsg1: "올바른 휴대폰 번호를 입력하십시오",
    mobile_errMsg2: "전화번호가 이미 등록되어 있습니다",
    token: "*인증 코드",
    token_error: "인증 코드 실수",
    update: "업데이트",
    update_success: "업데이트 성공!",
    done: "완료",
    Msg1: "휴대폰 번호와 이메일 주소가 모두 이미 사용되었습니다",
    Msg2: "이미 사용된 이메일 주소",
  },
  coupon: {
    title: "바우처",
    member_profile: "멤버 프로필",
    coupon: "바우처",
    active: "활성",
    redeemed: "상환",
    expired: "만료",
    type_of_vouchers_opt1: "바우처 유형을 선택하십시오",
    type_of_vouchers_opt2: "Type A",
    type_of_vouchers_opt3: "Type B",
    type_of_vouchers_opt4: "Type C",
    type_of_vouchers_opt5: "Type D",
    expiry_date: "만기일:",
    redeemed_store: "상환된레스토랑:",
    vaild_date: "유효일",
    description: "묘사",
    vouchers_code: "바우처 코드",
    done: "완료",
    no_coupon_msg: "이용할 수 있는 바우처가 없습니다",
  },
  editProfile: {
    title: "프로필 변경",
    member_profile: "멤버 프로필",
    editProfile: "프로필 편집",
    general: "일반 정보",
    security: "보안 정보",
    subscriptions: "구독",
    salutation: "*호칭",
    salutation_opt1: "호칭을 입력해주세요",
    salutation_opt2: "미스터",
    salutation_opt3: "미세스",
    salutation_opt4: "마담",
    salutation_opt5: "미스",
    salutation_opt6: "닥터",
    salutation_errMsg: "호칭을 입력해주세요",
    gender: "*성별",
    gender_opt1: "성별을 입력해주세요",
    gender_opt2: "남",
    gender_opt3: "여",
    gender_errMsg: "성별을 입력해주세요",
    first_name: "*이름",
    first_name_errMsg: "이름을 입력해주세요",
    last_name: "*성",
    last_name_errMsg: "성을 입력해주세요",
    birth: "*생일",
    mobile: "*휴대폰 번호",
    mobile_change: "변경",
    email: "*이메일 주소",
    email_change: "변경",
    language: "*언어",
    language_opt1: "언어 설정을 선택하십시오",
    language_opt2: "English",
    language_opt3: "简体中文",
    language_opt4: "한국어",
    update: "업데이트",
    previous_password: "*현재 비밀번호",
    previous_password_errMsg: "이전 비밀번호가 올바르지 않습니다",
    new_password: "*신규 비밀번호",
    new_password_errMsg1: "비밀번호를 입력하세요",
    new_password_errMsg2: "비밀번호는 8자 이상의 영숫자와 대문자와 특수 문자(?!@$%&*)를 포함해야 합니다",
    confirm_password: "*신규 비밀번호 확인",
    confirm_password_errMsg: "비밀번호 확인하십시오",
    subscriptions_txt1: "아래 확인란을 선택함으로써 본인은 Imperial Treasure Restaurant Group Pte Ltd('ITRG')가 본인이 제공한 개인 데이터 및/또는 Imperial Treasure Club 사용과 관련된 데이터를 수집, 사용, 저장 및/또는 공개하는 데 동의합니다. Imperial Treasure의 멤버십(이하 '데이터')은 Imperial Treasure와 대한민국 외부 서비스 제공업체와의 관계에서 발생하는 혜택을 포함하여 본인의 멤버십 또는 로열티 프로그램에서 발생하는 혜택을 완전히 누릴 수 있도록 관련 회사 또는 기타 제3자와 본인의 데이터를 공유합니다.",
    subscriptions_txt2: "임페리얼트레져 로부터 마케팅 목적의 메세지를 받는 것에 동의합니다. (선택)",
    by_email: "이메일로",
    by_mobile: "모바일로",
    update_success: "업데이트 성공!",
    done: "완료",
    password_update_success: "비밀번호가 성공적으로 변경되었습니다!",
  },
  profile: {
    title: "회원",
    logout: "로그아웃 ",
    click_to_renew: "클릭하여 갱신하다​",
    activation_now: "지금 활성화",
    menber_id: "회원 ID:",
    member: "회원",
    active: "활성 상태",
    expired: "만료",
    salutation: "호칭:",
    mobile: "휴대폰 번호:",
    birth: "생일:",
    password: "비밀번호:",
    ellipsis: "········",
    change: "변경",
    gender: "성별:",
    email: "이메일 주소:",
    cycle_start_date: "멤버십 시작일:",
    first_joined_date: "회원 가입일:",
    optin_email: "이메일 동의:",
    optin_sms: "문자 메시지 동의:",
    cycle_expiry_date: "멤버십 만료일:",
    language_preference: "기본 언어 설정:",
    spend: "KRW 이용금액",
    expires_on: "  만료",
    spend_required: "필요한 순지출액",
    to_upgrade_to: " 업그레이드",
    nett_spend_required: "유지하는 데 필요한 순 지출",
    nett: "네트",
    to_maintain_to: "유지하기 위해",
    silver_member: "은",
    gold_member: " 골드",
    diamond_member: "다이아몬드 회원으로",
    total_nett_spend: "총 순지출액",
    current_membership: "현재 회원 기간 내",
    i_dollar: "i-달러",
    last_update: "  최신 업데이트 : ",
    i_dollar_earned: "i-달러 적립",
    i_dollar_converted: "i-달러 전환",
    i_dollar_balance: "i-달러 잔액",
    i_dollar_automatically: "$50 i-달러는 5만원 전자 현금 바우처로 자동 전환됩니다.",
    vouchers: "바우처",
    currently_active: "현재 활성 상태",
    cash_vouchers: "현금 바우처",
    food_vouchers: "음식/프로모션 바우처",
    click_to_view: "더 보기",
    transaction: "거래",
    this_month: "이번 달 거래",
    last_3_months: "지난 3개월 거래",
    last_12_months: "지난 12개월 거래",
    transaction_details: "거래 내역",
    transaction_date: "거래 날짜",
    transaction_time: "거래 시간",
    outlet: "레스토랑",
    receipt: "영수증 번호",
    payment_method: "결제 방법",
    receipt_details: "영수증 상세내역",
    zero: ".0000",
    i_dollars_eared: "i-달러 적립:",
    total: "총 :",
    done: "완료",
    thankyou_msg: "이용해 주셔서 감사합니다!",
    activate_membership: "구성원 자격 활성화",
    activate_membership_1: "회원 자격을 활성화하여 다음과 같은 권한을 누리십시오:",
    activate_membership_2: "참여 레스토랑에서 지출한 금액에 대해 i-달러(10,000원당 i$1)를 적립하세요. i$50을 적립하면 모든 참여 레스토랑에서 사용할 수 있는 현금 전자 바우처 5만원을 받을 수 있습니다.",
    activate_membership_3: "",
    activate_membership_4: "",
    activate_membership_5: "",
    activate_membership_6: "",
    activate_membership_7: "",
    renew_membership: "",
    renew_membership_1: "",
    renew_membership_2: "",
    renew_membership_3: "",
    renew_membership_4: "",
    confirm_to_renew: "",
    confirm_to_activate: "활성화 확인",
    change_password: "비밀번호 변경",
    previous_password: "*현재 비밀번호",
    previous_password_errMsg: "이전 비밀번호가 올바르지 않습니다",
    new_password: "*신규 비밀번호",
    new_password_errMsg1: "비밀번호를 입력하고 확인하십시오",
    new_password_errMsg2: "비밀번호는 8자 이상의 영숫자와 대문자와 특수 문자(?!@$%&*)를 포함해야 합니다​",
    confirm_password: "*신규 비밀번호 확인",
    close: "X 닫다",
    member_activated: "회원 활성화",
    password_updated: " 비밀번호 업데이트됨",
    view_more: "더 보기",
    add_friend: "더하다",
    silver_tier: "실버 ",
    gold_tier: "골드 ",
    diamond_tier: "다이아몬드 ",
    spend_date_format: "만료",
  },
  transactionDetails: {
    transaction_date: "거래 날짜",
    outlet: "레스토랑",
    receipt_no: "수표/영수증 번호",
    amount_spend: "총 지불액",
    amount_qualified_for_i_dollar: "i-달러 적립 가능 금액",
    i_dollars: "i-달러",
    selected_start_date: "선택시작일자",
    selected_end_date: "선택종료일자",
    no_tranMsg: "선택한 기간 동안 거래 기록이 없습니다.",
    action: "액션",
    apply: "적용",
    view: "더 보기",
  },
  contactUs: {
    title: "문의하기",
    title_message: "아래에 피드백을 작성하여 제출해 주십시오. 영업일 기준 3-5일 이내에 답변 드리겠습니다",
    firstname: "이름",
    firstname_errMsg: "이름을 입력해주세요",
    lastname: "성",
    lastname_errMsg: "성을 입력해주세요",
    emailAddress: "이메일 주소",
    emailAddress_errMsg: "올바른 이메일 주소를 입력하십시오",
    mobile: "휴대폰 번호",
    mobile_errMsg: "올바른 휴대폰 번호를 입력하십시오",
    type: "유형",
    type_opt1: "피드백 메시지 유형을 입력하십시오: :",
    type_opt2: "옵션들",
    type_opt3: "문의",
    type_opt4: "피드백 ",
    type_opt5: "뉴스레터 구독",
    type_errMsg: "피드백 메시지 유형을 입력하십시오",
    message: "메세지",
    message_errMsg: "피드백 메시지를 입력하십시오",
    captcha: "보안 문자",
    captcha_errMsg: "보안 문자가 올바르지 않습니다",
    feedback_sucMsg: "소중한 의견을 제공해 주셔서 감사합니다. 곧 답변 드리겠습니다.",
    submit: "제출",
  },
  languageSetting: {
    title: "언어 설정",
    setting: "설정",
    language_preference: "언어 선호",
    language_preference_opt1: "언어 기본 설정을 선택하십시오",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_opt4: "한국인",
    language_preference_errMsg: "언어 기본 설정을 선택하십시오",
    submit: "제출",
  },
};
