export default {
  login: {
    login: "登 录",
    email: "电子邮箱",
    mobile: "手机号码",
    mobile2: "手机号码",
    password: "密码",
    errMsg1: "请输入电子邮箱和密码",
    errMsg2: "请输入手机号码和密码",
    errMsg3: "您的帐户不在此地区",
    errMsg4: "您的登录或密码不正確",
    errMsg5: "您的输入手机号码或密码不正確",
    errMsg6: "您的注册还未完成，请重新登记及完成付款步骤",
    forgotpw: "忘记密码？",
    register: "注 册",
    txt: "还不是御宝会员？查看",
    txt_2: "会员礼遇",
  },
  header: {
    home: "首页",
    eStore: "电子商城",
    PRivileges: "会员礼遇",
    register: "注册",
    highlights: "亮点",
    faq: "常见问题",
    tnp: "条款和隐私",
    profile: "会员档案",
  },
  footer: {
    aboutUs: "关于御宝",
    eStore: "电子商城",
    faq: "常见问题",
    tnp: "条款与隐私",
    pr: "参与分店",
    profile: "会员档案",
    contactUs: "联系我们",
    copyright: "2023©御宝饮食集团版权所有",
  },
  signUp: {
    signUp: "注册会员",
    title: "注册御宝忠诚计划会员",
    mustFill: "“*” 为必填项",
    step1: "账户资料",
    step2: "安全信息",
    step3: "信息订阅",
    step4: "推荐人",
    salutation: "称谓",
    salutation_opt1: "请选择您的称谓",
    salutation_opt2: "先生",
    salutation_opt3: "太太",
    salutation_opt4: "女士",
    salutation_opt5: "小姐",
    salutation_opt6: "博士",
    salutation_errMsg: "请选择称谓",
    firstname: "名字",
    firstname_errMsg: "请输入您的名字",
    lastname: "姓氏",
    lastname_errMsg: "请输入您的姓氏",
    birth: "出生日期",
    birth_errMsg: "请选择出生日期",
    birth_errMsg2: "您必须年满18岁",
    mobile: "手机号码",
    mobile_errMsg: "请输入有效电话",
    mobile_errMsg2: "手机号码已注册",
    emailAddress: "电子邮箱",
    emailAddress_errMsg: "请输入有效的电子邮箱",
    emailAddress_errMsg2: "电子邮箱已注册",
    confirmEmailAddress: "电子邮箱确认",
    confirmEmailAddress_errMsg: "请确认电子邮箱",
    gender: "性别",
    gender_opt1: "请选择您的性别",
    gender_opt2: "男",
    gender_opt3: "女",
    gender_errMsg: "请选择性别",
    language_preference: "语言选项",
    language_preference_opt1: "请选择您的语言选项",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_opt4: "한국인",
    language_preference_errMsg: "请选择语言选项",
    password_format: "密码至少为8个字符，其中需包含大小字母、数字和一个特殊字符 (?!@$%&*)",
    password: "*密码",
    password_errMsg: "请输入密码",
    confirm_password: "*密码确认",
    confirm_password_errMsg: "请再次输入密码",
    step3_consent_opt: "我同意所有列出的<link>条款和条件<link>。",
    step3_consent_txt: "• 我同意列出的<link>条款和条件<link>。\n• 我同意收集和使用我的个人信息\n• 我同意出于营销目的收集和使用我的个人信息\n• 我同意将我的个人信息传输给韩国或海外的第三方",
    tnc_errMsg: "请接受条款和条件以继续下一步",
    step3_title: "点击下方按钮，即表示您同意御宝、我司关联方、相关实体以及代表我司行事的第三方（“业务合作伙伴”）可收集、使用及披露您在会员门户网站上提供的个人信息。我司将向您提供由您同意接收的促销、特别优惠，促销、特别优惠可能由我司的业务合作伙伴直接发送，我司可能会向我司的业务合作伙伴提供您的个人信息。",
    step3_q: "我同意接收来自御宝的营销信息（可选）",
    step3_q_opt1: "通过电子邮件",
    step3_q_opt2: "通过电话",
    step4_q_err: "请选择如何得知御宝忠诚计划",
    step4_q: "您是如何得知御宝忠诚计划？",
    step4_q_opt1: "请选择",
    step4_q_opt2: "百乐达斯城,御宝轩",
    step4_q_opt3: "百乐达斯城广场,御宝点心火锅店",
    step4_q_opt16: "口碑介绍",
    step4_q_opt17: "社交媒体",
    step4_q_opt18: "其他",
    promo_code: "优惠码",
    promo_code_input_msg: "请输入有效的促销代码（如有）",
    promo_code_err_msg: "请输入有效的优惠码",
    promo_code_re_msg: "优惠码有效!",
    total: "总共：",
    refresh: "刷新",
    captcha: "验证码",
    captcha_errMsg: "验证码不正确",
    cancel: "取消",
    next: "下一步",
    back: "上一步",
    submitForm: "立即付款",
  },
  aboutUs: {
    aboutUs: "关于御宝",
    txt1: "御宝饮食集团",
    txt2: "御宝饮食集团创建于 2004 年。中华美食源远流长，御宝尊崇博大精深的美食文化，矢志继承这宝贵遗产。因此御宝一直坚持以道地的烹饪手法，为食客奉上美味丰盛的佳肴。加之御宝以服务至上，细致贴心，宗旨是提供舒适的用餐体验。",
    txt3: "迄今为此，御宝集团旗下囊括多过二十间店铺，各色美食，应有尽有。在经验领导及顾客支持下，御宝更荣获超過五十不同獎項包括：米其林指南上海两颗星 （2017 - 2023） 御宝轩 （益丰）, 米其林指南广州两颗星 （2020 - 2022）,  一颗星（2019） 御宝轩 （天汇广场）,米其林指南新加坡一颗星（2017 - 2019，2021-2022） 御宝阁潮州酒家 （爱雍·乌节）,  米其林指南香港澳门一颗星（2018 - 2022） 御宝轩 (尖沙咀), 米其林指南广州一颗星（2021- 2022） 御宝阁潮州酒家 （太古汇）, 米其林指南广州米其林餐盘获荣誉奖（2018， 2020）, 米其林指南法国米其林餐盘获荣誉奖（2020）, 米其林指南新加坡米其林餐盘获荣誉奖（2018，2019）,  米其林指南英国和爱尔兰米其林餐盘获荣誉奖（2019）, 美团点评黑珍珠餐厅指南二钻(2018), 全球最佳中餐厅，由庄布忠颁发, 亚洲 “50家最佳” 餐厅等诸多美誉（2013 - 2015）。",
    txt4: "御宝在新加坡，上海，广州，香港，仁川，伦敦和巴黎设有分店。",
  },
  faqs: {
    faqs: "FAQs",
    general: "常见问题",
    general_q1: "什么是御寶忠诚计划？",
    general_q1_opt1: "御寶忠诚计划是一个无卡的奖励项目，当会员在指定餐厅用餐时，即可享受专属礼遇和奖励。",
    general_q2: "如何成为御寶忠诚计划会员？",
    general_q2_opt1: "您可以在https://members.imperialtreasure.co.kr在线注册。或者，您也可以在任何参与的御寶餐馆注册。",
    general_q3: "韩国仁川有哪些指定餐厅，具体信息是？",
    general_q3_opt1: "会员特权仅在韩国参与的 Imperial Treasure 餐厅有效。",
    general_q3_opt2: "御寶軒",
    general_q3_opt2_1: "百乐达斯城广场1楼| 电话：(82) 32 729 2227",
    general_q3_opt3: "御寶火锅店",
    general_q3_opt3_1: "百乐达斯城广场1楼| 电话：(82) 32 729 5155",
    general_q4: "会员的有效期为多久？",
    general_q4_opt1: "会员身份有效期为自注册月份起的1年内。例如：如果您在2023年8月17日注册，会员身份则将于2024年8月31日到期。",
    general_q5: "可在哪里查询我的会员身份到期日？",
    general_q5_opt1: "您可登录会员专属网页https://members.imperialtreasure.co.kr，查询会员身份的到期日。",
    general_q6: "申请成为会员的最低年龄是多少？",
    general_q6_opt1: "顾客需要年满18周岁才能申请成为会员。",
    general_q7: "为什么注册时需要我的个人资料？",
    general_q7_opt1: "在 https://members.imperialtreasure.co.kr 注册您的详细信息以激活您的帐户。您将收到最新的会员促销、奖励、活动邀请和更多相关信息。",
    general_q8: "如何更新我的个人资料？",
    general_q8_opt1: "可通过会员专属网页https://members.imperialtreasure.co.kr登录账户，查看或更新您的个人资料。",
    general_q9: "如何查询我的积分，电子礼券和其他奖励？",
    general_q9_opt1: "可通过会员专属网页https://members.imperialtreasure.co.kr登录账户，查询您的积分余额，会员优惠及其他奖励。",
    general_q10: "如果我忘记了密码怎么办？",
    general_q10_opt1: "请登录https://members.imperialtreasure.co.kr并点击“忘记密码”，按照屏幕上的提示重置密码。",
    general_q11: "如果我对会员账户有疑问怎么办？",
    general_q11_opt1: "如果您对您的会员账户有任何疑问，请发送电子邮件至kr.club@imperialtreasure.com",
    general_q12: "我的会员身份可以转让吗？",
    general_q12_opt1: "不可以。",
    general_q13: "如何终止我的会员身份？",
    general_q13_opt1: "会员可以选择在失效日期之前的任何时间通过电邮至kr.club@imperialtreasure.com  联系我们以终止其会员资格。请注意，在终止时将不会退款和/或提前发放任何未使用的电子礼券。另一种方式是让您的会员资格自动失效，所有积分和/或未使用的电子礼券将被视为放弃使用。",
    membershipTypes: "会员等级",
    membershipTypes_q1: "会员有哪几种级别？",
    membershipTypes_q1_opt1: "会员级别共有三种，分别为：银，金和钻石。",
    membershipTypes_q2: "如何成为银，金或钻石会员？",
    membershipTypes_q2_opt1: "成为银员，可登入会员专属网站注册；",
    membershipTypes_q2_opt2: "成为金员，需要在会员有效期内累积消费达KRW10m * 或以上；",
    membershipTypes_q2_opt3: "成为钻石员，需要在会员有效期内累积消费达KRW25m * 或以上。",
    membershipTypes_q2_opt4: "*消费金额不包含10%服务费。",
    membershipTypes_q3: "如何保留我的会员身份？",
    membershipTypes_q3_opt1: "要维持金卡等级，只需在会员有效期内累积消费达KRW10m *或以上，即可保留会员身份。",
    membershipTypes_q3_opt2: "要维持钻石卡等级，只需在会员有效期内累积消费KRW25m*或以上，即可保留会员身份。",
    membershipTypes_q3_opt3: "*消费金额不包含10%服务费。",
    membership: "会员专属礼遇&奖励",
    membership_q1: "会员专属礼遇包括什么？",
    sliver: "银",
    gold: "金",
    diamond: "钻石",
    complimentary_treats: "敬送礼品",
    complimentary_treats_1_1: "北京式烤鸭（一只）",
    complimentary_treats_1_2: "-",
    complimentary_treats_1_3: "享有",
    complimentary_treats_1_4: "享有",
    complimentary_treats_2_1: "3件点心 （一份）",
    complimentary_treats_2_2: "-",
    complimentary_treats_2_3: "享有",
    complimentary_treats_2_4: "-",
    complimentary_treats_3_1: "星洲辣椒虾球 （一份）",
    complimentary_treats_3_2: "-",
    complimentary_treats_3_3: "享有",
    complimentary_treats_3_4: "-",
    complimentary_treats_4_1: "KRW250k 电子礼券",
    complimentary_treats_4_2: "-",
    complimentary_treats_4_3: "享有",
    complimentary_treats_4_4: "-",
    complimentary_treats_5_1: "香槟（一瓶）",
    complimentary_treats_5_2: "-",
    complimentary_treats_5_3: "-",
    complimentary_treats_5_4: "享有",
    complimentary_treats_6_1: "多宝鱼（一份）",
    complimentary_treats_6_2: "-",
    complimentary_treats_6_3: "-",
    complimentary_treats_6_4: "享有",
    complimentary_treats_7_1: "KRW500k 电子礼券",
    complimentary_treats_7_2: "-",
    complimentary_treats_7_3: "-",
    complimentary_treats_7_4: "享有",
    complimentary_treats_8_1: "烧味三拼（一份）",
    complimentary_treats_8_2: "-",
    complimentary_treats_8_3: "-",
    complimentary_treats_8_4: "享有",
    membership_q2: "如何知道我的会员优惠是否到期？",
    membership_q2_opt1: "要查阅各项礼遇及奖励的有效日期，只需登录会员专属网页https://members.imperialtreasure.co.kr，查询“礼券”，的详细信息。",
    membership_q3: "如何证明自己是会员？",
    membership_q3_1_1: "在参与餐厅用餐时，向我们的服务人员提供您电话号码即可证明会员身份",
    membership_q4: "如何领取赠送礼品？",
    membership_q4_1_1: "食或酒礼品能在御寶軒领取，需要提前一天预订。兑换现金礼券，请在付款前通知服务人员。会员必须在场以兑换礼券。",
    membership_q5: "作为一个企业客户，公司折扣能否与会员优惠并用？",
    membership_q5_opt1: "不可以，企业客户只能在公司折扣或会员优惠中二者选其一。",
    membership_q6: "会员在御寶的海外分店享有同样优惠吗？",
    membership_q6_opt1: "会员可在任何一间御宝分店赚取积分无论其位置如何。i$ 转换将基于注册基准国家和 御宝规定的货币汇率。对于电子优惠券的兑换，您只能在您注册账户的国家/地区兑换。",
    membership_q7: "会员卡是否可以转让？",
    membership_q7_opt1: "不可以。会员卡不宜转让。",
    earning: "赚取与兑换积分",
    earning_q1: "如何赚取积分？",
    earning_q1_opt1: "在指定门店每消费KRW10k *，便可赚取积分。会员本人必须在场以验证身份，过往消费的单据一律被视作无效。使用多个会员账户和拆分账单以进行积分赚取，是绝对不被允许的，每桌客人的每张单据，只能算进一个会员账户中。\n（*不包含10%服务费、电子现金礼券和季节性产品）",
    earning_q2: "如何使用积分？",
    earning_q2_opt1: "每赚取了50积分后，会员将在次日凌晨自动收到一张KRW50k的电子现金礼券*（只限会员有效期内使用）。 会员可在任何指定门店用餐时使用现金电子礼券，会员本人必须在场以验证身份，积分将会在账单结算后自动存入会员账户。\n(*系统每天定时自动转换积分为电子现金礼券)",
    earning_q3: "可以在电子商店在线购物赚取和使用积分或电子礼券吗？",
    earning_q3_opt1: "不能，除非另有规定。",
    earning_q4: "我的积分会过期吗？",
    earning_q4_opt1: "会的。一旦会员资格到期，积分也将过期，未使用的积分将有3个月的宽限期，会员必须在宽限期内将剩余积分转换为电子现金礼券，所有未被使用的积分将被清零。",
    earning_q5: "我的积分会在消费后即时显示出来吗？",
    earning_q5_opt1: "会的。除非在网络故障时，才会有所延迟；但正常情况下系统都能即时显示出赚取的积分。",
    earning_q6: "可以使用多个会员账户赚取和兑换积分吗？",
    earning_q6_opt1: "不可以。",
    earning_q7: "可以拆分账单以赚取积分或兑换积分吗？",
    earning_q7_opt1: "不可以。",
    earning_q8: "如果我不在场，我的家人或朋友可以使用我的会员卡赚取或兑换礼遇吗？",
    earning_q8_opt1: "不可以，会员本人必须在场享受会员礼遇。",
    earning_q9: "我可以通过第三方外卖平台在线下单赚取i$或兑换会员特权吗？",
    earning_q9_opt1: "不可以。赚取 i$ 和兑换会员特权不适用于通过第三方外卖平台进行在线订单。",
  },
  tnc: {
    title: "Terms And Privacy",
    membership: "Membership Terms And Privacy",
    membership_1_1: "1.1 Introduction",
    membership_1_1_1: 'Imperial Treasure South Korea Co. Ltd and Imperial Treasure F&B Management Pte. Ltd.(“Imperial Treasure”) would like to offer our privileged customers news and information regarding our products and services, including promotions, special offers and discounts.  We are offering you the opportunity to become a member of the Imperial Treasure Club (a "Member") to enjoy special privileges that Imperial Treasure may offer from time to time.',
    membership_1_2: "1.2 Collection and use of personal data",
    membership_1_2_1: "If you become a Member, you will need to provide Imperial Treasure certain personal information (including your name and contact details such as email address and phone number).  You will need to provide us with your mobile number for us to identify you. Your mobile number will be your username or unique identifier as a member of Imperial Treasure Club. Any personal data held by us about you will be held in accordance with our Privacy Notice.",
    membership_1_3: "1.3 Direct marketing",
    membership_1_3_1: 'By clicking on the "SUBMIT" button below, you agree that Imperial Treasure, our affiliates, related entities, and third parties acting on our behalf ("Business Partners"), may collect, use and disclose your personal data, which you have provided at the member\'s portal at https://members.imperialtreasure.com.kr (the "Member\'s Portal"), for providing marketing material that you have agreed to receive, in accordance with the Personal Information Protection Act (“PIPA”) and our Privacy Notice. Please note that marketing material may be sent directly by our Business Partners, and we may disclose your personal data to them for that purpose.',
    membership_1_4: "1.4 Membership Tiers",
    membership_1_4_1: "1.4.1 Membership registration, i-dollars, and Cash e-Vouchers",
    membership_1_4_1_1: "Where you sign up to become a Member, you will be required to provide your mobile number, among other information we may require from you, in order to sign up for the membership. This may be required online or by our restaurant staff depending on the circumstances.",
    membership_1_4_1_2: "Membership is strictly non-transferable and shall only be enjoyed by the Member in person.",
    membership_1_4_1_3: "As a Member, you earn one (01) i-dollar at the rate specified on the membership programme website [Link:Members.imperialtreasure.co.kr/benefits] for the country in which you signed up, when you spend at any participating Imperial Treasure restaurants (excluding service charge, tax, Cash e-vouchers used, and the purchase and use of Imperial Treasure gift vouchers). Please refer to the Member’s Portal for a list of participating Imperial Treasure restaurants. Member must be physically present at the point of dining and provide the registered mobile number to the service staff in order to earn i-dollars. Earning of i-dollars must be made at the point of transaction. Requests on i-dollar accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, Cash e-vouchers and i-dollar earned. I-dollars accrued shall expire upon expiry of membership. Any i-dollar accrued is however granted a three (03) months grace period and must be converted to Cash e-Vouchers within this grace period, failing which such i-dollar shall be deemed forfeited. Transactions made via third party vendors are not eligible for earning of i-dollars.",
    membership_1_4_1_4: "Earned i-dollars are converted in the Member Portal into Imperial Treasure e-vouchers of a specified value (“Cash e-Vouchers”) at the rate specified on the membership programme website [Link:Members.imperialtreasure.co.kr/benefits] for the country in which you signed up. Each Cash e-Voucher is valid for twelve (12) months from date of issue and valid for use within the membership period only. The Cash e-Voucher can be used to offset the bill when dining at any participating Imperial Treasure restaurants, subject to Imperial Treasure’s sole discretion and any other terms and conditions stated at [https://members.imperialtreasure.co.kr]. Neither i-dollars nor Cash e-Vouchers have any cash value and cannot be converted or redeemed for cash.",
    membership_1_4_2: "1.4.2 Membership Tiers",
    membership_1_4_2_1: "Unless otherwise determined by Imperial Treasure in its sole discretion, Silver Tier status is conferred on Members when they are invited to first join the membership programme and is valid for the period specified on the membership programme website [Link:https://members.imperialtreasure.co.kr] for the country in which you signed up. At the end of this period, if the Member has not been upgraded to the Gold Tier or Diamond Tier, the Member may be required to pay such renewal fee or meet the relevant renewal requirements as may be prescribed by Imperial Treasure in its sole discretion on the membership website [Link: https://members.imperialtreasure.co.kr] for the country in which you signed up. Imperial Treasure will give a reasonable advance notice to relevant Members thirty (30) days prior to the end of the membership programme period.",
    membership_1_4_2_2: "Gold Tier status is conferred on Members who spend at least the amount set out for Gold Tier on the membership programme website [Link: https://members.imperialtreasure.co.kr] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Gold Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Gold Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Silver Tier status.",
    membership_1_4_2_3: "Diamond Tier status is conferred on Members who spend at least the amount set out for Diamond Tier on the membership programme website [Link: https://members.imperialtreasure.co.kr] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Diamond Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Diamond Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Gold Tier or Silver Tier status accordingly.",
    membership_1_4_2_4: "Imperial Treasure may, in its sole discretion, alter, limit, or modify the Silver Tier, Gold Tier and/or Diamond Tier program rules, regulations, benefits, eligibility for membership, or any other feature of the Silver Tier, Gold Tier and/or Diamond Tier programme and/or may terminate the Silver Tier, Gold Tier and/or Diamond Tier programme at any time in its sole discretion, by giving reasonable advance notice of at least [30] days on the membership programme website and/or by email. Please check the membership programme website regularly for updates.",
    membership_1_4_3: "1.4.3 Membership Tier Rewards",
    membership_1_4_3_1: "Each membership tier also entitles Members to certain rewards and privileges, which may include other e-vouchers, free gifts and/or other dining benefits (“Rewards”). The current tier privileges are as set out in the membership programme website [Link: https://members.imperialtreasure.co.kr]. The validity and terms and conditions of such Rewards are as set out in [Link: Members.imperialtreasure.co.kr/tnc], which may be amended by Imperial Treasure at any time in its sole discretion, by giving reasonable notice on the membership programme website and/or by email. Rewards are always subject to availability. Imperial Treasure reserves the right to replace a Reward with another Reward of equivalent or similar value. Rewards do not have any cash value and cannot be converted or redeemed for cash.",
    membership_1_4_4: "1.4.4 Redemption of Rewards and Cash e-Vouchers",
    membership_1_4_4_1: "Member must be physically present (proof of identification required) at the point of dining in order to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made. Redemption of Rewards are subject to prevailing local law requirements.",
    membership_1_4_4_2: "Combining multiple membership accounts, combining bills or splitting the bills to earn i-dollars and/or redeem Rewards and membership privileges is strictly not allowed. Membership must be active at the point of redemption.",
    membership_1_5: "1.5 Amending personal data and terminating membership",
    membership_1_5_1: "You may amend your personal data or marketing preferences at any time via our Member’s Portal. You will need to inform us in writing at kr.club@imperialtreasure.com should you choose to terminate your membership. Please allow a few working days for changes to take effect. Kindly be advised that there will be no refunds made and/or early issuance of any unused Cash e-vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and all balance i-dollars and/or unused Cash e-vouchers will be deemed forfeited.",
    membership_1_6: "1.6 Termination and cancellation",
    membership_1_6_1: "We reserve the right to terminate the Member's membership if the membership account is found to be shared with another non-Member. We reserve the right to terminate your membership, in our absolute discretion, should you abuse any of the conditions and privileges bestowed on you as a Member.",
    membership_2: "2. Law and forum",
    membership_2_1: "These Terms, including all policies and additional terms referenced herein (“Membership Agreement”),  are governed by and construed in accordance with the laws of South Korea.",
    membership_2_2: "Any dispute or claim arising out of or in connection with this Membership Agreement, (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of South Korean courts.",
    membership_3: "3. Consent",
    membership_3_agree_all_terms: "□ I agree to all terms and conditions below",
    membership_3_agree_tnc: "□ I agree to the Terms and Conditions",
    membership_3_agree_tnc_link: "[Link: Members.imperialtreasure.co.kr/tnc ] (Required)",
    membership_3_agree_personal_info: "□ I agree to the collection and use of my personal information (Required)",
    membership_3_scroll_box: "[SCROLL BOX]",
    membership_3_purpose_of_collection: "Purpose of Collection and Use",
    membership_3_purpose_of_collection_1: "For restaurant and membership related processing",
    membership_3_personal_info: "Personal Information to be Collected",
    membership_3_personal_info_1: "Name, DOB, email, mobile",
    membership_3_retention_period: "Retention period",
    membership_3_retention_period_1: "Until membership cancellation",
    membership_3_agree_personal_info_mkt: "□ I agree to the collection and use of my personal information for marketing purposes (Optional)",
    membership_3_purpose_of_collection_2: "To provide you with marketing and promotional material and other information about our products (including promotions and special offers), as well as other products and services provided by our affiliates and partners",
    membership_3_personal_info_1: "Name, DOB, email, mobile",
    membership_3_retention_period_1: "Until membership cancellation",
    membership_3_agree_personal_info_third_parties: "□ I agree to the transfer of my personal information to third parties either in Korea or overseas (Optional)",
    membership_3_personal_data_trf: "1. For personal data transfer within Korea",
    membership_3_transferee: "Transferee",
    membership_3_transferee_1: "Imperial Treasure F&B Management Pte. Ltd.",
    membership_3_personal_info_tra: "Personal Information to be Transferred",
    membership_3_personal_info_tra_1: "Name, DOB, email, mobile, membership tier",
    membership_3_purpose_of_use: "Purpose of Use of Personal Information by the Transferee",
    membership_3_purpose_of_use_1: "For restaurant and membership related processing",
    membership_3_transferee_retention_period: "Transferee’s Retention Period",
    membership_3_transferee_retention_period_1: "Until membership cancellation",
    membership_3_personal_data_trf_outside: "2. For personal data transfer outside of Korea",
    membership_3_personal_data_trf_outside_transferee: "Transferee",
    membership_3_personal_data_trf_outside_transferee_1_1: "Xgate Pte Ltd data.privacy@xgate.com",
    membership_3_personal_data_trf_outside_transferee_1_2: "- Imperial Treasure Restaurant Group Pte Ltd \n- Imperial Treasure F & B Management Pte Ltd \n-  Imperial Treasure (Teochew Cuisine) Pte Ltd \n- Imperial Treasure Nan Bei Restaurant Pte Ltd \n- Imperial Treasure Noodle House Pte Ltd \n- Imperial Treasure Cantonese Cuisine Pte Ltd \n- Imperial Treasure Fine Dining Restaurant Pte Ltd \n- Imperial Treasure Windows of Hong Kong Pte Ltd \n- Imperial Treasure Steamboat Pte Ltd \n- Imperial Treasure Shanghai Cuisine Pte Ltd \n- Imperial Treasure F&B Shanghai Co., Ltd \n- Imperial Treasure Xin Zhou F&B Management Co Ltd \n- Imperial Treasure F&B Management Shanghai Co., Ltd \n- Imperial Treasure (Guangzhou) Co., Ltd. \n- Imperial Treasure (International) Co., Ltd. \n- Imperial Treasure South Korea Co., Ltd \n- Imperial Treasure F & B Management Pte Ltd \n- Imperial Treasure (UK) Co., Ltd \n- Imperial Treasure (France) \n- dpo@imperialtreasure.com",
    membership_3_personal_data_trf_outside_time_and_method: "Time and Method of Transfer",
    membership_3_personal_data_trf_outside_time_and_method_1_1: "Real-time / Online transfer",
    membership_3_personal_data_trf_outside_time_and_method_1_2: "Real-time / Online transfer",
    membership_3_personal_data_trf_outside_personal_info_tra: "Personal Information to be Transferred",
    membership_3_personal_data_trf_outside_personal_info_tra_1_1: "Name, DOB, email, mobile, membership tier",
    membership_3_personal_data_trf_outside_personal_info_tra_1_2: "Name, DOB, email, mobile, membership tier",
    membership_3_personal_data_trf_outside_purpose_of_use: "Purpose of Use of Personal Information by the Transferee",
    membership_3_personal_data_trf_outside_purpose_of_use_1_1: "Membership related benefits",
    membership_3_personal_data_trf_outside_purpose_of_use_1_2: "Membership related benefits",
    membership_3_personal_data_trf_outside_transferee_retention_period: "Transferee’s Retention Period",
    membership_3_personal_data_trf_outside_transferee_retention_period_1_1: "Until membership cancellation",
    membership_3_personal_data_trf_outside_transferee_retention_period_1_2: "Until membership cancellation",
    membership_3_agree_receive_mkt_msg: "□ I agree to receive marketing messages from Imperial Treasure (Optional)",
    privacy: "Privacy Notice",
    privacy_1: "1. INTRODUCTION",
    privacy_1_1: 'Imperial Treasure South Korea Co. Ltd (collectively referred as "Imperial Treasure", "us", "we") is committed to protect the privacy of visitors to this website (the "Website"). This Privacy Notice sets out how Imperial Treasure collects, uses or discloses personal data that you have provided to us through the Website. By visiting the Website you are accepting and consenting to the collection, use and disclosure of personal data described in this Privacy Notice. If you do not agree with this Privacy Notice, please do not use the Website. This Privacy Notice does not apply to other websites to which we link ("Third Party Website" or "Third Party Websites").',
    privacy_1_2: "This Privacy Notice also describes your data protection rights, including a right to object to some of the processing which Imperial Treasure carries out. More information about your rights, and how to exercise them, is set out in the following section: “9. What rights do I have”.",
    privacy_1_3: 'Maintaining the privacy of our customers’ information is important to us. We do not disclose rent or sell our customers’ personal data to third parties (except as described in "6. Disclosures of personal data to third parties").',
    privacy_2: "2. COLLECTION AND USE OF PERSONAL DATA",
    privacy_2_1: "We collect the following personal data when you register as a user of the Website and make purchases through the Website:",
    privacy_2_1_1: "email address",
    privacy_2_1_2: "your name, address and phone number (including delivery addresses if different);",
    privacy_2_1_3: "billing address, including your name, address and phone number; and",
    privacy_2_1_4: "any other personal data provided to us when you submit any comment, question or feedback to us, request services from us, or disclose your personal data to us in any way",
    privacy_2_2: "We use your personal data for the following purposes:",
    privacy_2_2_1: "To fulfil a contract, or take steps linked to a contract: this is relevant where you make a purchase from us. This includes:",
    privacy_2_2_1_1: "to fulfil your order and deliver it to you;",
    privacy_2_2_1_2: "to facilitate and track your purchases from us (including in our restaurants or via our websites); and",
    privacy_2_2_1_3: "to provide you with service support;",
    privacy_2_2_2: "As required by Imperial Treasure to conduct our business and pursue our legitimate interest, in particular:",
    privacy_2_2_2_1: "to facilitate your registration as a user of the Website;",
    privacy_2_2_2_2: "to use information you provide to provide products and services you have requested;",
    privacy_2_2_2_3: "(if you use a card payment for any purchase on the Website) we use third parties to check the validity of the sort code, account number and card number you submit in order to prevent fraud; and;",
    privacy_2_2_2_4: "to respond to comments, queries and feedback.",
    privacy_2_2_3: "Where you give us consent:",
    privacy_2_2_3_1: "we provide you with marketing and promotional material and other information about our products (including promotions and special offers), as well as other products and services provided by our affiliates and partners and",
    privacy_2_2_3_2: "on other occasions where we ask you for consent, we will use the data for the purposes which we explain at that time.",
    privacy_2_2_4: "For purposes which are required by law: in response to requests by government or law enforcement authorities conducting an investigation.",
    privacy_2_3: "The provision of your name, family name, contact number, date of birth, country code and email address are mandatory so that we can contact you and fulfil any of your order(s): if any required data is not provided, then we will not be able to fulfil your order(s). All other provision of your information is optional.",
    privacy_2_4: "Additionally, through the Website, we may gather certain aggregated and anonymous information about the use of the Website, such as how frequently certain areas of the Website are visited, including through the use of cookies, web beacons, Google Analytics and other technologies.",
    privacy_2_5: "We may use cookies, web beacons, Google Analytics and other technologies for the following purposes:",
    privacy_2_5_1: "to identify the causes of problems arising at web servers and to resolve these problems;",
    privacy_2_5_2: "to improve the contents of the Website;",
    privacy_2_5_3: "to customize the content of the Website and our emails to suit your individual interests or purposes and selectively display best-matched pages to you, including tracking your past purchases in order to provide you with new services or special promotions;",
    privacy_2_5_4: "to utilize your browsing history on the Website for demographic research; and",
    privacy_2_5_5: "to obtain static data of web visitors and aggregated website visitation statistics which are anonymous in nature.",
    privacy_2_6: "You may reject our use of cookies by configuring your browser to disable the use of cookies. However, you may no longer be able to receive services or personalized features of the Website which rely on the use of cookies.",
    privacy_3: "3. SENDING OF EMAILS TO MEMBERS",
    privacy_3_1: "Genuine emails from Imperial Treasure always will come from an address ending @imperialtreasure.com. Do not open any attachments or click any links from suspicious e-mails. If you wish to report a suspicious email claiming to be from Imperial Treasure that you believe is a forgery, you may email to kr.marketing@imperialtreasure.com",
    privacy_4: "4. RELYING ON OUR LEGITIMATE INTERESTS",
    privacy_4_1: "We have carried out balancing tests for all the data processing we carry out on the basis of our legitimate interests, which we have described above. You can obtain information on any of our balancing tests by contacting us using the details set out later in this notice.",
    privacy_5: "5. WITHDRAWING CONSENT OR OTHERWISE OBJECTING TO DIRECT MARKETING",
    privacy_5_1: "Wherever we rely on your consent, you will always be able to withdraw that consent, although we may have other legal grounds for processing your data for other purposes, such as those set out above. In some cases, we can send you direct marketing without your consent, where we rely on our legitimate interests.  You have an absolute right to opt-out of direct marketing, or profiling we carry out for direct marketing, at any time. You can do this by following the instructions in the communication where this is an electronic message, or by contacting us using the details set out below.",
    privacy_6: "6. DISCLOSURES OF PERSONAL DATA TO THIRD PARTIES",
    privacy_6_1: "Your personal data may also be transferred out of South Korea from time to time. Such transfers will be carried out in accordance with the PIPA. We will not provide your personal data to a third party without your prior consent.",
    privacy_6_2: "List of third parties to which personal data is provided:",
    privacy_6_2_a: "1. For personal data transfer within Korea",
    privacy_6_2_1: "Transferee",
    privacy_6_2_1_1: "Imperial Treasure F&B Management Pte. Ltd.",
    privacy_6_2_2: "Personal Information to be Transferred",
    privacy_6_2_2_1: "Name, DOB, email, mobile",
    privacy_6_2_3: "Purpose of Use of Personal Information by the Transferee",
    privacy_6_2_3_1: "For restaurant and membership related processing",
    privacy_6_2_4: "Transferee’s Retention Period",
    privacy_6_2_4_1: "Until membership cancellation",
    privacy_6_3_a: "2. For personal data transfer outside of Korea",
    privacy_6_3_1: "Name of Transferee\n(Privacy Contact)",
    privacy_6_3_1_1: "Xgate Pte Ltd\ndata.privacy@xgate.com",
    privacy_6_3_1_2: "- Imperial Treasure Restaurant Group Pte Ltd \n- Imperial Treasure F & B Management Pte Ltd\n- Imperial Treasure (Teochew Cuisine) Pte Ltd\n- Imperial Treasure Nan Bei Restaurant Pte Ltd\n- Imperial Treasure Noodle House Pte Ltd\n- Imperial Treasure Cantonese Cuisine Pte Ltd\n- Imperial Treasure Fine Dining Restaurant Pte Ltd\n- Imperial Treasure Windows of Hong Kong Pte Ltd\n- Imperial Treasure Steamboat Pte Ltd\n- Imperial Treasure Shanghai Cuisine Pte Ltd\n- Imperial Treasure F&B Shanghai Co., Ltd\n- Imperial Treasure Xin Zhou F&B Management Co Ltd\n- Imperial Treasure F&B Management Shanghai Co., Ltd\n- Imperial Treasure (Guangzhou) Co., Ltd.\n- Imperial Treasure (International) Co., Ltd.\n- Imperial Treasure South Korea Co., Ltd\n- Imperial Treasure F & B Management Pte Ltd\n- Imperial Treasure (UK) Co., Ltd",
    privacy_6_3_2: "Time and Method of Transfer",
    privacy_6_3_2_1: "Real-time / Online transfer",
    privacy_6_3_2_2: "Real-time / Online transfer",
    privacy_6_3_3: "Personal Information to be Transferred",
    privacy_6_3_3_1: "Name, DOB, email, mobile, membership tier",
    privacy_6_3_3_2: "Name, DOB, email, mobile, membership tier",
    privacy_6_3_4: "Purpose of Use of Personal Information by the Transferee",
    privacy_6_3_4_1: "Membership related benefits",
    privacy_6_3_4_2: "Membership related benefits",
    privacy_6_3_5: "Transferee’s Retention Period",
    privacy_6_3_5_1: "Until membership cancellation",
    privacy_6_3_5_2: "Until membership cancellation",
    privacy_6_4: "List of entrustees to which personal information is provided:",
    privacy_6_4_a: "1. For personal data transfer within Korea",
    privacy_6_4_1: "Transferee",
    privacy_6_4_1_1: "Imperial Treasure F&B Management Pte. Ltd.",
    privacy_6_4_2: "Personal Information to be Transferred",
    privacy_6_4_2_1: "Name, DOB, email, mobile, membership tier",
    privacy_6_4_3: "Purpose of Use of Personal Information by the Transferee",
    privacy_6_4_3_1: "CRM loyalty programme",
    privacy_6_4_4: "Transferee’s Retention Period",
    privacy_6_4_4_1: "Until membership cancellation",
    privacy_6_5: "Where information is transferred outside the EEA, and where this is to a stakeholder or vendor in a country that is not subject to an adequacy decision by the EU Commission, data is adequately protected by EU Commission approved standard contractual clauses, or a vendor's Processor Binding Corporate Rules.  A copy of the relevant mechanism can be provided for your review on request to our Data Protection Office (please refer to the section below: '12 Contact Us').",
    privacy_7: "7. HOW WE PROTECT YOUR INFORMATION",
    privacy_7_1: "We are committed to protecting the information we receive from you. We adopt reasonable physical, technical and administrative practices to help protect the confidentiality, security and integrity of data, including personal data, stored on our system. We believe the measures we have implemented reduce the likelihood of security problems to a level appropriate to the type of data involved. Although we take reasonable steps to protect the information we receive from you, no computer system is completely secure and we cannot be held liable for any loss you may suffer for unauthorized access or loss of any data provided to the Website.",
    privacy_8: "8. LINKS TO THIRD PARTY SITES",
    privacy_8_1: "The Website may contain links to third party websites and microsites, including third party payment gateways, whose privacy practices may differ from those of ours. If you submit personal data, including payment information, to any of those websites, that personal data is not subject to this Privacy Notice and will be subject to the third party's privacy practices instead.",
    privacy_8_2: "We therefore encourage you to review the privacy statement of any website you visit. By clicking on or activating such links and leaving the Website, we can no longer exercise control over any data or information which you give to any other entity after leaving the Website, and any access to such other websites is entirely at your own risk.",
    privacy_9: "9. WHAT RIGHTS DO I HAVE",
    privacy_9_1: "You have the right to ask us for a copy of your personal data; to correct, delete or restrict (stop any active) processing of your personal data; and to obtain the personal data you provide to us for a contract or with your consent in a structured, machine readable format, and to ask us to share (port) this data to another data controller.",
    privacy_9_2: "In addition, you can object to the processing of your personal data in some circumstances (in particular, where we don’t have to process the data to meet a contractual or other legal requirement, or where we are using the data for direct marketing).",
    privacy_9_3: "These rights may be limited, for example if fulfilling your request would reveal personal data about another person, where they would infringe the rights of a third party (including our rights) or if you ask us to delete information which we are required by law to keep or have compelling legitimate interests in keeping. Relevant exemptions are included in the applicable laws and regulations. We will inform you of relevant exemptions we rely upon when responding to any request you make.",
    privacy_9_4: "If you are based in the EU and have unresolved concerns, you have the right to complain to an EU data protection authority where you live, work or where you believe a breach may have occurred.",
    privacy_9_5: 'To exercise any of these rights, or to obtain other information, such as a copy of a legitimate interests balancing test, you can get in touch with us – by contacting our Data Protection Manager (See "12 Contact us" below).  To correct your personal data, you can do so by signing into your user account and updating your personal data. We will only disclose personal data in accordance with an access request to the individual who registered his/her personal data with the Website, and only after confirming that the person requesting access is indeed the registered party.',
    privacy_9_6: "You may also contact our Data Protection Manger to withdraw your consent to our use of your personal data.",
    privacy_9_7: "Please allow ten (10) working days for your request to be processed.",
    privacy_10: "10. RETENTION OF INFORMATION",
    privacy_10_1: "Where we process registration data, we do this for as long as you are an active user of the Website and for six (6) years after this.",
    privacy_10_2: "Where we process personal data for marketing purposes or with your consent, we will process such data until you ask us to stop and for a short period after this (to allow us to implement your requests). We also keep a record of the fact that you have asked us not to send you direct marketing or not to process your data so that we can respect your request in the future.",
    privacy_10_3: "Where we process personal data in connection with performing a contract, we keep such data for six (6) years from your last interaction with us.",
    privacy_10_4: "If you withdraw your consent to our collection, use or disclosure of your personal data, we may not be able to provide you with services that require such personal data. Please also note that your personal data may still be retained on our database to the extent permitted by law.",
    privacy_11: "11. PRIVACY POLICY CHANGES",
    privacy_11_1: "If we decide to change our Privacy Notice for the Website, we will post the revised Privacy Notice here and, if appropriate, notify you by email. Please check back frequently to see any updates or changes to our Privacy Notice.",
    privacy_12: "12. CONTACT US",
    privacy_12_1: "If you have any questions about this Privacy Notice, please contact our Data Protection Manager at DPO@imperialtreasure.com.",
  },
  outlets: {
    outlets: "餐厅",
    restaurant_name: "餐厅名称",
    address: "住址",
    contact: "联系",
    email: "电子邮件",
    outlets_1_1: "御宝轩",
    outlets_1_2: "Paradise City, Main Building 1F \n186 Yeongjonghaeannnam-ro, 321 beon-gil, Jung-gu Incheon 22382, Republic of Korea",
    outlets_1_3: "(82) 32 729 2227",
    outlets_1_4: "finechinese_pcity@imperialtreasure.com",
    outlets_2_1: "御宝点心火锅店",
    outlets_2_2: "Paradise City Plaza, Main Building 1F \n186 Yeongjonghaeannnam-ro, 321 beon-gil, Jung-gu Incheon 22382, Republic of Korea",
    outlets_2_3: "(82) 32 729 5155",
    outlets_2_4: "dimsumsteamboat_pcity@imperialtreasure.com",
  },
  privilege: {
    title: "特权",
    privilege: "会员优惠",
    privelege_txt: "每消费 KRW10k* 可赚取 i$1\n累计 i$50 以换取KRW50k电子礼券",
    silver: "银",
    silver_1: "仅限邀请",
    gold: "金",
    gold_1: "凡在会员有效期内消费满 KRW10m*, 便可成为金等级会员。",
    gold_2: "1 x KRW250K 奖励电子礼券",
    gold_3: "敬送礼品：\n北京式烤鸭一只 \n点心一份 (烧卖，虾饺，猪肠粉) \n星洲辣椒虾球一份",
    diamond: "钻石",
    diamond_1: "凡在会员有效期内消费满 KRW25m*, 便可成为钻石等级会员。",
    diamond_2: "1 x KRW250K 奖励电子礼券",
    diamond_3: "敬送礼品：\n北京式烤鸭一只 \n香槟一瓶 \n多宝鱼一份 \n烧味三拼一份 ",
    remark: "*不包括 VAT, 折扣，电子礼券兑换和佳节食品.",
    sign_up_now: "立即注册",
  },
  resetPassword: {
    title: "忘记密码",
    reset_password: "重置密码",
    email: "*请输入电子邮箱",
    email_input: "请输入电子邮箱",
    email_errMsg: "请输入有效的电子邮箱",
    mobile: "*请输入手机号码",
    mobile_errMsg: "请输入有效电话",
    new_password: "*新密码",
    new_password_errMsg1: "请输入密码",
    new_password_errMsg2: "密码必须至少包含8个字母数字字符，并且至少包含一个大写字母和一个特殊字符（?!@$%&*）",
    confirm_password: "*确认新密码",
    confirm_password_errMsg: "请确认密码",
    reset_password2: "前去重置密码",
    has_been_reset: "密码已重置！",
    login: "登录",
  },
  forgetPassword: {
    title: "忘记密码",
    reset_password: "重置密码",
    forgot_password: "忘记密码？",
    reset_pw_method: "请选择密码重置方式",
    by_mobile: "通过手机",
    by_email: "通过电子邮件",
    email: "*请输入电子邮箱",
    email_input: "请输入电子邮箱",
    email_errMsg: "请输入有效的电子邮箱",
    mobile: "*请输入手机号码",
    mobile_errMsg: "请输入有效电话",
    reset_password2: "前去重置密码",
    reset_password_sent_email: "重置密码链接已发送到您的电子邮箱。请注意查收！",
    reset_password_sent_mobile: "重置密码链接已发送到您的手机短讯。请注意查收！",
    next: "下一步",
    back: "上一步",
  },
  updateEmail: {
    title: "更新电子邮箱",
    member_profile: "会员档案",
    updateEmail: "更新电子邮箱",
    new_email: "*新电子邮箱",
    sent: "发送",
    send_token: "发送验证码",
    email_errMsg1: "请输入有效的电子邮件",
    email_errMsg2: "电子邮件已注册",
    token: "*验证码",
    token_error: "验证码错误",
    update: "更新",
    update_success: "更新成功！",
    done: "完成",
    Msg1: "手机和电子邮箱都已使用",
    Msg2: "电子邮箱已使用",
  },
  updateMobile: {
    title: "修改手机号码",
    member_profile: "会员档案",
    updateMobile: "修改手机号码",
    new_mobile: "*新手机号码",
    sent: "发送",
    send_token: "发送验证码",
    mobile_errMsg1: "请输入有效电话",
    mobile_errMsg2: "手机已注册",
    token: "*验证码",
    token_error: "验证码错误",
    update: "更新",
    update_success: "更新成功！",
    done: "完成",
    Msg1: "手机和电子邮件都已使用",
    Msg2: "电子邮件已使用",
  },
  coupon: {
    title: "礼劵",
    member_profile: "会员档案",
    coupon: "礼劵",
    active: "有效的",
    redeemed: "已兑换",
    expired: "过期",
    type_of_vouchers_opt1: "请选择礼劵类型",
    type_of_vouchers_opt2: "类型A",
    type_of_vouchers_opt3: "类型B",
    type_of_vouchers_opt4: "类型C",
    type_of_vouchers_opt5: "类型D",
    expiry_date: "到期日期：",
    redeemed_store: "赎回商店：",
    vaild_date: "无效日期",
    description: "说明",
    vouchers_code: "礼劵代码",
    done: "完成",
    no_coupon_msg: "没有可用礼劵",
  },
  editProfile: {
    title: "编辑个人资料",
    member_profile: "会员档案",
    editProfile: "编辑个人资料",
    general: "账户资料",
    security: "安全信息",
    subscriptions: "信息订阅",
    salutation: "*称谓",
    salutation_opt1: "请选择您的称谓",
    salutation_opt2: "先生",
    salutation_opt3: "太太",
    salutation_opt4: "女士",
    salutation_opt5: "小姐",
    salutation_opt6: "博士",
    salutation_errMsg: "请选择称谓",
    gender: "*性别",
    gender_opt1: "请选择您的性别",
    gender_opt2: "男性",
    gender_opt3: "女性",
    gender_errMsg: "请选择性别",
    first_name: "*名字",
    first_name_errMsg: "请输入名字",
    last_name: "*姓氏",
    last_name_errMsg: "请输入姓氏",
    birth: "*出生日期",
    mobile: "*手机号码",
    mobile_change: "修改",
    email: "*电子邮件",
    email_change: "修改",
    language: "*语言",
    language_opt1: "请选择您的语言选项",
    language_opt2: "English",
    language_opt3: "简体中文",
    language_opt4: "한국어",
    update: "更新",
    previous_password: "*旧密码",
    previous_password_errMsg: "旧密码密码不正确",
    new_password: "*新密码",
    new_password_errMsg1: "请输入密码",
    new_password_errMsg2: "密码必须至少包含8个字母数字字符，并且至少包含一个大写字母和一个特殊字符（?!@$%&*）",
    confirm_password: "*确认密码",
    confirm_password_errMsg: "请确认密码",
    subscriptions_txt1: "点击下方按钮，即表示您同意御宝、我司关联方、相关实体以及代表我司行事的第三方（“业务合作伙伴”）可收集、使用及披露您在会员门户网站上提供的个人信息。我司将向您提供由您同意接收的促销、特别优惠，可能由我司的业务合作伙伴直接发送，我司可能会向我司的业务合作伙伴提供您的个人信息。",
    subscriptions_txt2: "我同意接收来自御宝的营销信息（可选）",
    by_email: "通过电子邮件",
    by_mobile: "通过电话",
    update_success: "更新成功！",
    done: "完成",
    password_update_success: "密码修改成功！",
  },
  profile: {
    title: "会员",
    logout: "登出",
    click_to_renew: "单击以更新​",
    activation_now: "立即激活",
    menber_id: "会员号码:",
    member: "会员",
    active: "有效的",
    expired: "过期",
    salutation: "称谓：",
    mobile: "手机号码：",
    birth: "出生日期：",
    password: "密码：",
    ellipsis: "········",
    change: "更改",
    gender: "性别：",
    email: "电子邮件：",
    cycle_start_date: "等级开始日期：",
    first_joined_date: "首次加入日期：",
    optin_email: "选择电子邮件：",
    optin_sms: "选择短信：",
    cycle_expiry_date: "周期到期日期：",
    language_preference: "语言选项：",
    spend: "KRW 消费",
    expires_on: "  到期时间",
    spend_required: "还需净消费",
    to_upgrade_to: "升级为",
    nett_spend_required: "还需净消费維持为",
    nett: "净",
    to_maintain_to: "維持为",
    silver_member: "銀卡会员",
    gold_member: "金卡会员",
    diamond_member: "钻石卡会员",
    total_nett_spend: "总净消费金额",
    current_membership: "在当前会员年份*",
    i_dollar: "i-Dollar",
    last_update: "  最新一次更新：",
    i_dollar_earned: "賺取的 i-Dollar",
    i_dollar_converted: "已兌換的 i-Dollar",
    i_dollar_balance: "剩餘的 i-Dollar",
    i_dollar_automatically: "$50 i-Dollars 将自动转换为KRW50k的现金礼券",
    vouchers: "礼劵",
    currently_active: "当前有效",
    cash_vouchers: "现金礼劵",
    food_vouchers: "食品/促销券",
    click_to_view: "单击查看",
    transaction: "交易记录",
    this_month: "当月记录",
    last_3_months: "最近3个月",
    last_12_months: "过去12个月",
    transaction_details: "交易明细",
    transaction_date: "交易日期",
    transaction_time: "交易时间",
    outlet: "餐厅",
    receipt: "单据编码",
    payment_method: "付款方式",
    receipt_details: "单据明细",
    zero: ".0000",
    i_dollars_eared: "i-DOLLARS 赚取:",
    total: "总额：",
    done: "完成",
    thankyou_msg: "谢谢你选择我们的餐厅!",
    activate_membership: "激活会员资格",
    activate_membership_1: "激活您的会员身份享受以下礼遇*：",
    activate_membership_2: "在参与分店每消费KRW10,000，即可赚取i$1累积i$50可兑换KRW5,0000电子礼劵，仅在参与分店使用",
    activate_membership_3: "2 张 50 新元注册电子礼券（有效期为 3 个月；不能同时使用）",
    activate_membership_4: "1 张 100 新元生日电子礼券（仅限生日当月，且单笔有效消费满 280 新元时可使用）",
    activate_membership_5: "1 张免费和牛（1 份）电子礼券，可于任何御宝火锅餐厅兑换",
    activate_membership_6: "1 张免费老虎石斑鱼（1 份）电子礼券，可于任何御宝火锅餐厅兑换",
    activate_membership_7: "率先收到最新信息和私人活动专属邀请！",
    renew_membership: "会员更新",
    renew_membership_1: "您的会员资格已失效，现在只需S$38，即可更新会员资格，并在新一年的会员周期中收到以下电子礼劵：",
    renew_membership_2: "1 张 50 新元更新电子礼券（有效期为签发之日起 3 个月）",
    renew_membership_3: "1 张 100 新元生日电子礼券（仅限生日当月，且满最低消费 280 新元时可使用）",
    renew_membership_4: "3 张 30 新元的火锅汤底电子礼券（有效期为签发之日起 12 个月，每次来就餐仅限 (01) 次兑换，仅限堂食）",
    confirm_to_renew: "确认续订",
    confirm_to_activate: "确认激活",
    change_password: "修改密码",
    previous_password: "*旧密码",
    previous_password_errMsg: "旧密码不正确",
    new_password: "*新密码",
    new_password_errMsg1: "请输入并确认密码",
    new_password_errMsg2: "密码必须至少包含8个字母数字字符，并且至少包含一个大写字母和一个特殊字符（?!@$%&*）​",
    confirm_password: "*确认密码",
    close: "X 关闭",
    member_activated: "会员已激活",
    password_updated: "密码修改成功！",
    view_more: "查看更多",
    add_friend: "添加",
    silver_tier: "銀卡",
    gold_tier: "金卡",
    diamond_tier: "钻石卡",
    spend_date_format: "年过期",
  },
  transactionDetails: {
    transaction_date: "交易日期",
    outlet: "餐厅",
    receipt_no: "单据编码",
    amount_spend: "消费金额",
    amount_qualified_for_i_dollar: "符合 i-Dollar 金额",
    i_dollars: "i-Dollars",
    selected_start_date: "选定开始日期",
    selected_end_date: "选定结束日期",
    no_tranMsg: "所选期间无交易记录。",
    action: "动作",
    apply: "应用",
    view: "查看",
  },
  contactUs: {
    title: "联系我们",
    title_message: "請填寫並在下面提交您的反饋，我們將在3-5個工作天内回覆您。",
    firstname: "名字",
    firstname_errMsg: "请输入您的名字",
    lastname: "姓氏",
    lastname_errMsg: "请输入您的姓氏",
    emailAddress: "电子邮箱",
    emailAddress_errMsg: "请输入有效的电子邮箱",
    mobile: "手机号码",
    mobile_errMsg: "请输入有效电话",
    type: "类型",
    type_opt1: "请输入您的反馈信息类型 :",
    type_opt2: "联络我们",
    type_opt3: "查询",
    type_opt4: "反馈",
    type_opt5: "电子报",
    type_errMsg: "请输入您的反馈信息类型",
    message: "信息",
    message_errMsg: "请输入您的反饋信息",
    captcha: "验证码",
    captcha_errMsg: "验证码不正确",
    feedback_sucMsg: "感谢您与我们联系并向我们提供宝贵的反馈。 \n我们会尽快回复您。",
    submit: "提交",
  },
  languageSetting: {
    title: "语言设置",
    setting: "设置",
    language_preference: "语言选项",
    language_preference_opt1: "请选择您的语言选项",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_opt4: "한국인",
    language_preference_errMsg: "请选择语言选项",
    submit: "提交",
  },
};
