import React, { useState, useCallback } from "react";
import {
  View,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  ImageBackground,
  StyleSheet,
  ActivityIndicator,
  Modal,
  Image,
  RefreshControl,
} from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import Header from "../components/header";
import Captcha from "../components/captcha";
import IntlPhoneInput from "../components/IntlPhoneInput";
import RNPickerSelect from "react-native-picker-select";
import { API_MIDDLEWARE, API_REGION } from "@env";
import i18n from "../components/i18n";
import CookieConsent from "../components/CookieConsent";
import axios from "axios";
import NewMobileHeader from "../components/newMobileHeader";

const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

const ContactUs = () => {
  const navigation = useNavigation();
  const route = useRoute();

  const [isLoading, setIsLoading] = useState(false);
  const [lastname, setLastname] = useState("");
  const [lastnameErr, setLastnameErr] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [firstnameErr, setFirstnameErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [dialCode, setDialCode] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [recaptchaErr, setRecaptchaErr] = useState(false);
  const [type, setType] = useState("");
  const [typeErr, setTypeErr] = useState(false);
  const [message, setMessage] = useState("");
  const [messageErr, setMessageErr] = useState(false);
  const [feedbackSucMsg, setFeedbackSucMsg] = useState(false);
  const [successOverlay, setSuccessOverlay] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const validEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const checkCaptcha = (status) => {
    setRecaptchaValid(status);
  };

  const onChangePhoneInput = ({
    dialCode,
    unmaskedPhoneNumber,
    phoneNumber,
    isVerified,
  }) => {
    console.log(dialCode, unmaskedPhoneNumber, phoneNumber, isVerified);
    setPhone(unmaskedPhoneNumber.replaceAll("-", ""));
    setDialCode(dialCode.replace("+", ""));
    setValidPhone(isVerified);
  };

  const submitForm = () => {
    let errors = [];
    setIsLoading(true);

    setLastnameErr(false);
    setFirstnameErr(false);
    setEmailErr(false);
    setPhoneErr(false);
    setRecaptchaErr(false);
    setTypeErr(false);
    setMessageErr(false);
    setSuccessOverlay(false);

    if (lastname == "") {
      errors.push("err");
      setLastnameErr(true);
    }

    if (firstname == "") {
      errors.push("err");
      setFirstnameErr(true);
    }

    if (!validEmail(email)) {
      errors.push("err");
      setEmailErr(true);
    }

    if (!validPhone) {
      errors.push("err");
      setPhoneErr(true);
    }

    if (!recaptchaValid) {
      errors.push("err");
      setRecaptchaErr(true);
    }

    if (type == "") {
      errors.push("err");
      setTypeErr(true);
    }

    if (message == "") {
      errors.push("err");
      setMessageErr(true);
    }

    if (errors.length) {
      setIsLoading(false);
    } else {
      console.log("Checking passed!");

      let data = {
        lastname: lastname,
        firstname: firstname,
        email: email,
        phone: dialCode + phone.replace(/\s/g, ""),
        type: type,
        message: message,
      };

      addContactUs(data, (c1) => {
        setIsLoading(true);
        setFeedbackSucMsg(false);
        console.log("data :", data);
        console.log("c1 :", c1);

        if ((c1.status = "success")) {
          console.log("Added customer's feedback successful.");
          setSuccessOverlay(true);
          setFeedbackSucMsg(true);
          setLastname("");
          setFirstname("");
          setEmail("");
          setPhone("");
          setDialCode("");
          setType("");
          setMessage("");
          setIsLoading(false);
        } else {
          console.log("Failed to add user's feedback.");
          alert("Failed to add your feedback.");
          setFeedbackSucMsg(false);
          setIsLoading(false);
        }
      });
    }
  };

  const addContactUs = async (data, callback) => {
    // console.log("data-addContactUs :", data);
    let formData = new FormData();
    formData.append("method", "contactUs");
    formData.append("type", data.type);
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    formData.append("email", data.email);
    formData.append("mobile", data.phone);
    formData.append("message", data.message);
    formData.append("region", API_REGION);

    try {
      const response = await axios.post(API_MIDDLEWARE, formData);
      callback(response.data);
    } catch (error) {
      console.log("contact us error :", error);
      throw error;
    }
  };
  return (
    <View className="flex-1 md:bg-[#efefef]">
      {isLoading && (
        <View
          className="left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-full"
          style={{ position: "fixed" }}
          pointerEvents={"none"}
        >
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}
      <ImageBackground
        source={require("../assets/login-bg.png")}
        resizeMode="cover"
        className="flex-1 justify-start items-center w-full h-full"
      >
        <SafeAreaView className="flex-1 bg-white flex justify-start items-center w-full md:bg-transparent ">
          <ScrollView
            className="w-full flex-1 h-auto md:mt-4"
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
          >
            <Header />
            <NewMobileHeader />
            <MobileHeader title={i18n.t("contactUs.title")} />

            <View className="w-11/12 md:w-4/5 max-w-7xl h-auto m-auto bg-white p-5 md:mt-10 md:mb-20">
              <View className="flex hidden md:flex">
                <Text className="flex item-center justify-center text-base text-xl md:text-2xl text-brandgold font-semibold font-Crimson">
                  {i18n.t("contactUs.title")}
                </Text>
              </View>

              <View className="w-full h-[0.5px] bg-brandgold mt-2 hidden md:flex"></View>

              <View className="md:flex md:flex-row md:flex-wrap justify-between md:mt-3">
                <Text className="text-[#4E5969] text-xs mt-1 mb-0 font-PingFangSC">
                  {i18n.t("contactUs.title_message")}
                </Text>
              </View>

              <View className="md:flex md:flex-row md:flex-wrap justify-between pb-4 mt-2">
                <View className="md:w-[48%] md:mt-2 mb-2">
                  <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">
                    {i18n.t("contactUs.firstname")}
                  </Text>
                  <TextInput
                    placeholder=""
                    className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
                    value={firstname}
                    onChangeText={setFirstname}
                  ></TextInput>

                  {firstnameErr && (
                    <Text className="color-red-500 my-1">
                      {i18n.t("contactUs.firstname_errMsg")}
                    </Text>
                  )}

                  <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">
                    {i18n.t("contactUs.lastname")}
                  </Text>
                  <TextInput
                    placeholder=""
                    className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
                    value={lastname}
                    onChangeText={setLastname}
                  ></TextInput>

                  {lastnameErr && (
                    <Text className="color-red-500 my-1">
                      {i18n.t("contactUs.lastname_errMsg")}
                    </Text>
                  )}

                  <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">
                    {i18n.t("contactUs.emailAddress")}
                  </Text>
                  <TextInput
                    placeholder=""
                    className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
                    value={email}
                    onChangeText={setEmail}
                  ></TextInput>

                  {emailErr && (
                    <Text className="color-red-500 my-1">
                      {i18n.t("contactUs.emailAddress_errMsg")}
                    </Text>
                  )}

                  <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">
                    {i18n.t("contactUs.mobile")}
                  </Text>
                  <IntlPhoneInput
                    onChangeText={onChangePhoneInput}
                    defaultCountry={API_REGION}
                    containerStyle={{
                      flex: 1,
                      flexDirection: "row",
                      paddingHorizontal: 0,
                    }}
                    flagStyle={{ display: "none" }}
                    phoneInputStyle={{
                      color: "#86909C",
                      height: 32,
                      width: "100%",
                      borderWidth: 1,
                      borderColor: "#F2F3F5",
                      backgroundColor: "#F2F3F5",
                      paddingHorizontal: 15,
                    }}
                    dialCodeTextStyle={{
                      color: "#86909C",
                      lineHeight: 32,
                      borderColor: "#F2F3F5",
                      backgroundColor: "#F2F3F5",
                      paddingHorizontal: 15,
                    }}
                    placeholder={phone}
                  />

                  {phoneErr && (
                    <Text className="color-red-500 my-1">
                      {i18n.t("signUp.mobile_errMsg")}
                    </Text>
                  )}
                </View>

                <View className="md:w-[48%] md:mt-2 mb-2">
                  <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">
                    {i18n.t("contactUs.type")}
                  </Text>
                  <RNPickerSelect
                    value={type}
                    onValueChange={(value) => setType(value)}
                    items={[
                      {
                        label: i18n.t("contactUs.type_opt2"),
                        value: "options",
                      },
                      {
                        label: i18n.t("contactUs.type_opt3"),
                        value: "enquiry",
                      },
                      {
                        label: i18n.t("contactUs.type_opt4"),
                        value: "feedback",
                      },
                      {
                        label: i18n.t("contactUs.type_opt5"),
                        value: "newsletter_subscription",
                      },
                    ]}
                    placeholder={{
                      label: i18n.t("contactUs.type_opt1"),
                      value: "",
                    }}
                    style={pickerSelectStyles}
                    useNativeAndroidPickerStyle={false}
                  />

                  {typeErr && (
                    <Text className="color-red-500 my-1">
                      {i18n.t("contactUs.type_errMsg")}
                    </Text>
                  )}

                  <Text className="text-[#4E5969] text-xs mt-5 mb-1 font-PingFangSC">
                    {i18n.t("contactUs.message")} :
                  </Text>
                  <TextInput
                    // placeholder={i18n.t("contactUs.message_errMsg")+"..."}
                    className="h-20 w-full bg-[#F2F3F5] color-[#121212] px-4"
                    value={message}
                    onChangeText={setMessage}
                    // keyboardType="numeric"
                    multiline={true}
                  ></TextInput>

                  {messageErr && (
                    <Text className="color-red-500 my-1">
                      {i18n.t("contactUs.message_errMsg")}
                    </Text>
                  )}

                  <View className="mt-3 mb-12">
                    <Captcha returnStatus={checkCaptcha} />

                    {recaptchaErr && (
                      <Text className="color-red-500 my-1">
                        {i18n.t("contactUs.captcha_errMsg")}
                      </Text>
                    )}
                  </View>
                </View>
              </View>

              <View className="md:flex md:flex-row md:flex-wrap justify-between pb-4 mt-4 my-auto">
                <TouchableOpacity
                  className="w-auto h-8 max-h-8 bg-brandgold md:mt-8 mt-4 px-4 "
                  onPress={() => submitForm()}
                >
                  <Text className="text-white w-full leading-8 text-center font-PingFangSC">
                    {i18n.t("contactUs.submit")}
                  </Text>
                </TouchableOpacity>
              </View>

              <Modal
                animationType="slide"
                visible={successOverlay}
                transparent={true}
              >
                <View
                  className="h-screen top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]"
                  // style={{ position: "fixed" }}
                >
                  <View className="w-11/12 max-w-xs bg-white px-6 py-10 relative">
                    <TouchableOpacity
                      className="absolute right-1 top-1"
                      onPress={() => {
                        setSuccessOverlay(false);

                        navigation.replace(
                          route.name, // current page/screen
                          null, // no params
                          null, // no "sub-action"
                          Math.random().toString()
                        );

                        // navigation.push("Profile");
                      }}
                    >
                      <Image
                        className="w-5 h-5"
                        source={require("../assets/btn-close.png")}
                      />
                    </TouchableOpacity>

                    <Text className="text-lg font-semibold text-[#86909C] text-center py-8 font-Crimson ">
                      {i18n.t("contactUs.feedback_sucMsg")}
                    </Text>

                    <View className="text-right flex items-end mb-8">
                      <TouchableOpacity
                        className="w-full h-8 max-h-8 bg-brandgold mt-4 mr-0"
                        onPress={() => {
                          setSuccessOverlay(false);
                          navigation.replace(
                            route.name, // current page/screen
                            null, // no params
                            null, // no "sub-action"
                            Math.random().toString()
                          );
                        }}
                      >
                        <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                          {i18n.t("editProfile.done")}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </Modal>
            </View>
          </ScrollView>
          <Footer />
          <CookieConsent />
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
  inputAndroid: {
    color: "#86909C",
    height: 38,
    fontSize: 15,
    lineHeight: 38,
    width: "100%",
    backgroundColor: "#F2F3F5",
    borderColor: "#F2F3F5",
    borderWidth: 1,
    paddingHorizontal: 15,
  },
  inputWeb: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
});

export default ContactUs;
