import React from "react";
import {
  View,
  Text,
  ImageBackground,
  SafeAreaView,
  ScrollView,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import Header from "../components/header";
import i18n from "../components/i18n";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";

const Tnc = () => {
  const navigation = useNavigation();

  return (
    <View className="md:bg-[#efefef]">
      <ImageBackground
        source={require("../assets/login-bg.png")}
        resizeMode="cover"
        className="flex-1 justify-start items-center w-full h-full"
      >
        <SafeAreaView className="bg-white flex justify-start items-center w-full md:bg-transparent">
          <Header />
          <NewMobileHeader />
          <MobileHeader title={i18n.t("tnc.title")} />
          <View className="w-11/12 md:w-4/5 md:max-w-screen-xl md:h-auto md:m-auto md:bg-white md:p-7 mt-6 md:mb-24 pb-10">
            <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">
              {i18n.t("tnc.membership")}
            </Text>

            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

            <Text className="text-sm text-[#494949] mt-6 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_1_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_2_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_3_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-6 font-semibold">
              {i18n.t("tnc.membership_1_4")}
            </Text>

            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_4_1")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-2">
              {i18n.t("tnc.membership_1_4_1_1")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-2">
              {i18n.t("tnc.membership_1_4_1_2")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-2">
              {i18n.t("tnc.membership_1_4_1_3")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-6">
              {i18n.t("tnc.membership_1_4_1_4")}
            </Text>

            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_4_2")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-2">
              {i18n.t("tnc.membership_1_4_2_1")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-2">
              {i18n.t("tnc.membership_1_4_2_2")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-2">
              {i18n.t("tnc.membership_1_4_2_3")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-6">
              {i18n.t("tnc.membership_1_4_2_4")}
            </Text>

            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_4_3")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_4_3_1")}
            </Text>

            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_4_4")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-2 ">
              {i18n.t("tnc.membership_1_4_4_1")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_4_4_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_5")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_5_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_6")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_6_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-2">
              {i18n.t("tnc.membership_2_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6">
              {i18n.t("tnc.membership_2_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold hidden">
              {i18n.t("tnc.membership_3")}
            </Text>
            {/* <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_3_1")}
            </Text> */}

            {/* Consent Area */}
            <ScrollView horizontal={true}>
              <View className="mt-2 max-w-[780px] overflow-hidden overflow-x-scroll hidden">
                <View className="mt-1">
                  <View className="flex flex-row bg-white">
                    <Text className="w-[100%] border border-black text-sm font-semibold pl-2">
                      {i18n.t("tnc.membership_3_agree_all_terms")}
                    </Text>
                  </View>

                  <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 ">
                    <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                  </View>

                  <View className="flex flex-row bg-white">
                    <Text className="w-[100%] border border-black border-t-0 border-b-0 text-sm font-semibold pl-2">
                      {i18n.t("tnc.membership_3_agree_tnc")}
                    </Text>
                  </View>
                  <View className="flex flex-row bg-white">
                    <Text className="w-[100%] border border-black border-t-0 border-b-0 text-red-500 pl-2">
                      {i18n.t("tnc.membership_3_agree_tnc_link")}
                    </Text>
                  </View>

                  <View className="flex flex-row bg-white border border-black border-t-0 border-b-0">
                    <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                  </View>

                  <View className="flex flex-row bg-white">
                    <Text className="w-[100%] border border-black border-t-0 border-b-0 text-sm font-semibold pl-2">
                      {i18n.t("tnc.membership_3_agree_personal_info")}
                    </Text>
                  </View>

                  <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 ">
                    <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                  </View>

                  <View className="flex flex-col justify-center items-center bg-white border border-black border-t-0 border-b-0">
                    <View className="w-[98%] border border-black">
                      <Text className="w-full border border-black border-t-0 border-b-0 border-l-0 border-r-0 pl-2">
                        {i18n.t("tnc.membership_3_scroll_box")}
                      </Text>

                      <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 border-l-0 border-r-0">
                        <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                      </View>

                      <View className="flex justify-center items-center">
                        <View className="flex flex-row w-[98%] border-t-0 border-b-0 border-black">
                          <Text className="w-1/3 border border-black border-r-0 px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t("tnc.membership_3_purpose_of_collection")}
                          </Text>
                          <Text className="w-1/3 border border-black border-r-0 px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t("tnc.membership_3_personal_info")}
                          </Text>
                          <Text className="w-1/3 border border-black px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t("tnc.membership_3_retention_period")}
                          </Text>
                        </View>

                        <View className="flex flex-row w-[98%] border-t-0 border-b-0 border-black">
                          <Text className="w-1/3 border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t("tnc.membership_3_purpose_of_collection_1")}
                          </Text>
                          <Text className="w-1/3 border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t("tnc.membership_3_personal_info_1")}
                          </Text>
                          <Text className="w-1/3 border border-black border-t-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t("tnc.membership_3_retention_period_1")}
                          </Text>
                        </View>

                        <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 border-l-0 border-r-0">
                          <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 ">
                    <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                  </View>

                  <View className="flex flex-row bg-white">
                    <Text className="w-[100%] border border-black border-t-0 border-b-0 text-sm font-semibold pl-2">
                      {i18n.t("tnc.membership_3_agree_personal_info_mkt")}
                    </Text>
                  </View>

                  <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 ">
                    <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                  </View>

                  <View className="flex flex-col justify-center items-center bg-white border border-black border-t-0 border-b-0">
                    <View className="w-[98%] border border-black">
                      <Text className="w-full border border-black border-t-0 border-b-0 border-l-0 border-r-0 pl-2">
                        {i18n.t("tnc.membership_3_scroll_box")}
                      </Text>

                      <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 border-l-0 border-r-0">
                        <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                      </View>

                      <View className="flex justify-center items-center">
                        <View className="flex flex-row w-[98%] border-t-0 border-b-0 border-black">
                          <Text className="w-1/3 border border-black border-r-0 px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t("tnc.membership_3_purpose_of_collection")}
                          </Text>
                          <Text className="w-1/3 border border-black border-r-0 px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t("tnc.membership_3_personal_info")}
                          </Text>
                          <Text className="w-1/3 border border-black px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t("tnc.membership_3_retention_period")}
                          </Text>
                        </View>

                        <View className="flex flex-row w-[98%] border-t-0 border-b-0 border-black">
                          <Text className="w-1/3 border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t("tnc.membership_3_purpose_of_collection_2")}
                          </Text>
                          <Text className="w-1/3  border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t("tnc.membership_3_personal_info_1")}
                          </Text>
                          <Text className="w-1/3 border border-black border-t-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t("tnc.membership_3_retention_period_1")}
                          </Text>
                        </View>

                        <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 border-l-0 border-r-0">
                          <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 ">
                    <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                  </View>

                  <View className="flex flex-row bg-white">
                    <Text className="w-[100%] border border-black border-t-0 border-b-0 text-sm font-semibold pl-2">
                      {i18n.t(
                        "tnc.membership_3_agree_personal_info_third_parties"
                      )}
                    </Text>
                  </View>

                  <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 ">
                    <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                  </View>

                  <View className="flex flex-col justify-center items-center bg-white border border-black border-t-0 border-b-0">
                    <View className="w-[98%] border border-black">
                      <Text className="w-full border border-black border-t-0 border-b-0 border-l-0 border-r-0 pl-2">
                        {i18n.t("tnc.membership_3_scroll_box")}
                      </Text>

                      <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 border-l-0 border-r-0">
                        <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                      </View>

                      <Text className="w-full border border-black border-t-0 border-b-0 border-l-0 border-r-0 pl-2">
                        {i18n.t("tnc.membership_3_personal_data_trf")}
                      </Text>

                      <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 border-l-0 border-r-0">
                        <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                      </View>

                      <View className="flex justify-center items-center">
                        <View className="flex flex-row w-[98%] border-t-0 border-b-0 border-black">
                          <Text className="w-1/4 border border-black border-r-0 px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t("tnc.membership_3_transferee")}
                          </Text>
                          <Text className="w-1/4 border border-black border-r-0 px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t("tnc.membership_3_personal_info_tra")}
                          </Text>
                          <Text className="w-1/4 border border-black border-r-0 px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t("tnc.membership_3_purpose_of_use")}
                          </Text>
                          <Text className="w-1/4 border border-black px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t(
                              "tnc.membership_3_transferee_retention_period"
                            )}
                          </Text>
                        </View>

                        <View className="flex flex-row w-[98%] border-t-0 border-b-0 border-black">
                          <Text className="w-1/4 border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t("tnc.membership_3_transferee_1")}
                          </Text>
                          <Text className="w-1/4  border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t("tnc.membership_3_personal_info_tra_1")}
                          </Text>
                          <Text className="w-1/4  border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t("tnc.membership_3_purpose_of_use_1")}
                          </Text>
                          <Text className="w-1/4 border border-black border-t-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t(
                              "tnc.membership_3_transferee_retention_period_1"
                            )}
                          </Text>
                        </View>

                        <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 border-l-0 border-r-0">
                          <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                        </View>
                      </View>

                      <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 border-l-0 border-r-0">
                        <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                      </View>

                      <Text className="w-full border border-black border-t-0 border-b-0 border-l-0 border-r-0 pl-2">
                        {i18n.t("tnc.membership_3_personal_data_trf_outside")}
                      </Text>

                      <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 border-l-0 border-r-0">
                        <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                      </View>

                      <View className="flex justify-center items-center">
                        <View className="flex flex-row w-[98%] border-t-0 border-b-0 border-black">
                          <Text className="w-[40%] border border-black border-r-0 px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_transferee"
                            )}
                          </Text>
                          <Text className="w-[15%] border border-black border-r-0 px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_time_and_method"
                            )}
                          </Text>
                          <Text className="w-[15%] border border-black border-r-0 px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_personal_info_tra"
                            )}
                          </Text>
                          <Text className="w-[15%] border border-black border-r-0 px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_purpose_of_use"
                            )}
                          </Text>
                          <Text className="w-[15%] border border-black px-4 py-3 text-sm font-semibold text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_transferee_retention_period"
                            )}
                          </Text>
                        </View>

                        <View className="flex flex-row w-[98%] border-t-0 border-b-0 border-black">
                          <Text className="w-[40%] border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_transferee_1_1"
                            )}
                          </Text>
                          <Text className="w-[15%]  border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_time_and_method_1_1"
                            )}
                          </Text>
                          <Text className="w-[15%]  border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_personal_info_tra_1_1"
                            )}
                          </Text>
                          <Text className="w-[15%]  border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_purpose_of_use_1_1"
                            )}
                          </Text>
                          <Text className="w-[15%] border border-black border-t-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_transferee_retention_period_1_1"
                            )}
                          </Text>
                        </View>

                        <View className="flex flex-row w-[98%] border-t-0 border-b-0 border-black">
                          <Text className="w-[40%] border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-start">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_transferee_1_2"
                            )}
                          </Text>
                          <Text className="w-[15%]  border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_time_and_method_1_2"
                            )}
                          </Text>
                          <Text className="w-[15%]  border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_personal_info_tra_1_2"
                            )}
                          </Text>
                          <Text className="w-[15%]  border border-black border-t-0 border-r-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_purpose_of_use_1_2"
                            )}
                          </Text>
                          <Text className="w-[15%] border border-black border-t-0 px-4 py-3 text-sm font-normal text-center">
                            {i18n.t(
                              "tnc.membership_3_personal_data_trf_outside_transferee_retention_period_1_2"
                            )}
                          </Text>
                        </View>

                        <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 border-l-0 border-r-0">
                          <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                        </View>
                      </View>
                    </View>

                    <View className="flex flex-row bg-white border border-black border-t-0 border-b-0 border-l-0 border-r-0">
                      <Text className="w-[100%] border border-black border-t-0 border-b-0 mt-3"></Text>
                    </View>
                  </View>

                  <View className="flex flex-row bg-white">
                    <Text className="w-[100%] border border-black border-t-0 border-b-0 text-sm font-semibold pl-2">
                      {i18n.t("tnc.membership_3_agree_receive_mkt_msg")}
                    </Text>
                  </View>

                  <View className="flex flex-row bg-white border border-black border-t-0 border-b-0">
                    <Text className="w-[100%] border border-black border-t-0  mt-3"></Text>
                  </View>
                </View>
              </View>
            </ScrollView>

            <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">
              {i18n.t("tnc.privacy")}
            </Text>

            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_1_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_1_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_1_3")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_1")}
            </Text>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_1_1")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_1_2")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_1_3")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_1_4")}
              </Text>
            </View>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_2")}
            </Text>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_1")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_1_1")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_1_2")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_1_3")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2_1")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2_2")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2_3")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2_4")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_3")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_3_1")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_3_2")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_4")}
              </Text>
            </View>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_4")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_5")}
            </Text>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_5_1")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_5_2")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_5_3")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_5_4")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_5_5")}
              </Text>
            </View>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_6")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_3_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_4")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_4_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_5")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_5_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_6")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_6_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_6_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-2 ">
              {i18n.t("tnc.privacy_6_2_a")}
            </Text>

            <ScrollView horizontal={true}>
              <View className="mt-2 mb-3 max-w-[780px] overflow-hidden overflow-x-scroll">
                <View className="mt-2">
                  <View className="flex flex-row bg-brandgold">
                    <Text className="w-[25%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">
                      {i18n.t("tnc.privacy_6_2_1")}
                    </Text>
                    <Text className="w-[25%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">
                      {i18n.t("tnc.privacy_6_2_2")}
                    </Text>
                    <Text className="w-[25%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">
                      {i18n.t("tnc.privacy_6_2_3")}
                    </Text>
                    <Text className="w-[25%] border border-black px-4 py-3 text-sm font-semibold">
                      {i18n.t("tnc.privacy_6_2_4")}
                    </Text>
                  </View>

                  <View className="flex flex-row bg-white">
                    <Text className="w-[25%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_2_1_1")}
                    </Text>
                    <Text className="w-[25%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_2_2_1")}
                    </Text>
                    <Text className="w-[25%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_2_3_1")}
                    </Text>
                    <Text className="w-[25%] border border-black border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_2_4_1")}
                    </Text>
                  </View>
                </View>
              </View>
            </ScrollView>

            <Text className="text-sm text-[#494949] mt-1 mb-2 ">
              {i18n.t("tnc.privacy_6_3_a")}
            </Text>

            <ScrollView horizontal={true}>
              <View className="mt-2 mb-3 max-w-[780px] overflow-hidden overflow-x-scroll">
                <View className="mt-2">
                  <View className="flex flex-row bg-brandgold">
                    <Text className="w-[40%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">
                      {i18n.t("tnc.privacy_6_3_1")}
                    </Text>
                    <Text className="w-[15%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">
                      {i18n.t("tnc.privacy_6_3_2")}
                    </Text>
                    <Text className="w-[15%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">
                      {i18n.t("tnc.privacy_6_3_3")}
                    </Text>
                    <Text className="w-[15%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">
                      {i18n.t("tnc.privacy_6_3_4")}
                    </Text>
                    <Text className="w-[15%] border border-black px-4 py-3 text-sm font-semibold">
                      {i18n.t("tnc.privacy_6_3_5")}
                    </Text>
                  </View>

                  <View className="flex flex-row bg-white">
                    <Text className="w-[40%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_3_1_1")}
                    </Text>
                    <Text className="w-[15%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_3_2_1")}
                    </Text>
                    <Text className="w-[15%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_3_3_1")}
                    </Text>
                    <Text className="w-[15%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_3_4_1")}
                    </Text>
                    <Text className="w-[15%] border border-black border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_3_5_1")}
                    </Text>
                  </View>

                  <View className="flex flex-row bg-white">
                    <Text className="w-[40%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_3_1_2")}
                    </Text>
                    <Text className="w-[15%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_3_2_2")}
                    </Text>
                    <Text className="w-[15%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_3_3_2")}
                    </Text>
                    <Text className="w-[15%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_3_4_2")}
                    </Text>
                    <Text className="w-[15%] border border-black border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_3_5_2")}
                    </Text>
                  </View>
                </View>
              </View>
            </ScrollView>

            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_6_4")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-2 ">
              {i18n.t("tnc.privacy_6_4_a")}
            </Text>

            <ScrollView horizontal={true}>
              <View className="mt-2 mb-6 max-w-[780px] overflow-hidden overflow-x-scroll">
                <View className="mt-2">
                  <View className="flex flex-row bg-brandgold">
                    <Text className="w-[25%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">
                      {i18n.t("tnc.privacy_6_4_1")}
                    </Text>
                    <Text className="w-[25%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">
                      {i18n.t("tnc.privacy_6_4_2")}
                    </Text>
                    <Text className="w-[25%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">
                      {i18n.t("tnc.privacy_6_4_3")}
                    </Text>
                    <Text className="w-[25%] border border-black px-4 py-3 text-sm font-semibold">
                      {i18n.t("tnc.privacy_6_4_4")}
                    </Text>
                  </View>

                  <View className="flex flex-row bg-white">
                    <Text className="w-[25%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_4_1_1")}
                    </Text>
                    <Text className="w-[25%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_4_2_1")}
                    </Text>
                    <Text className="w-[25%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_4_3_1")}
                    </Text>
                    <Text className="w-[25%] border border-black border-t-0 px-4 py-3 text-sm">
                      {i18n.t("tnc.privacy_6_4_4_1")}
                    </Text>
                  </View>
                </View>
              </View>
            </ScrollView>

            <Text className="text-sm text-[#494949] mt-1 mb-2 ">
              {i18n.t("tnc.privacy_6_5")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_7")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_7_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_8")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_8_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_8_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_9")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_9_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_9_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_9_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_9_4")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_9_5")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_9_6")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_9_7")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_10")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_4")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_11")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_11_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_12")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_12_1")}
            </Text>
          </View>

          <Footer />
          <CookieConsent />
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

export default Tnc;
